export const primaryColors = {
  25: "#FEFFFC",
  50: "#f6f9ec",
  100: "#eaf2d5",
  200: "#d7e5b1",
  300: "#BED586",
  400: "#a2c05b",
  500: "#84a53d",
  600: "#65832d",
  700: "#4F6526",
  800: "#405123",
  900: "#384621",
  950: "#1c250e"
}

export const neutralColors = {
  25: "#FFFCFC",
  50: "#FAFAFA",
  100: "#F5F5F5",
  200: "#E5E5E5",
  300: "#D4D4D4",
  400: "#A3A3A3",
  500: "#737373",
  950: "#0A0A0A"
}

export const secondaryColors = {
  25: '#FCFDFF',
  50: '#f0f6fe',
  100: '#dde9fc',
  200: '#c2dafb',
  300: '#98c3f8',
  400: '#5c9cf2',
  500: '4480ed'

}

export const statusColors = {
  red: "#FC8C8C",
  yellow: "#FFDE88",
  green: "#95CF94"
}

export const yellowColors = {
  50: "#FFF0C6",
  200: "#FFDE88"
}

export const goldColors = {
  primary: '#DAAE7E',
  hover: '#C49367'
}