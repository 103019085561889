import { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField } from '@mui/material';
import { AwarenessOptimizationGoals, EngagementOptimizationGoals, EngagementWebsiteConversionOptimizationGoals, TrafficOptimizationGoals } from '../../../context/meta/MetaTypes';
import styled from 'styled-components';
import { beautifyString, convertObjectToArray } from '../../../utils/helpers';
import { desktop_h4, desktop_p3 } from '../../../styles/textStyles';

export type AdSetOptimizationGoalEditDialogProps = {
  customerId: number,
  dialogOpen: boolean,
  entireMetaObj: any,
  onCloseDialog: (
    update: boolean, 
    data?: {
      optimization_goal: EngagementOptimizationGoals | AwarenessOptimizationGoals | TrafficOptimizationGoals
    }
  ) => void,
}


const AdSetOptimizationGoalEditDialog = (props: AdSetOptimizationGoalEditDialogProps) => {
  const { customerId, dialogOpen, entireMetaObj, onCloseDialog } = props

  const { campaign, adSetModel } = entireMetaObj

  const [optimizationGoal, setOptimizationGoal] = useState(adSetModel?.optimization_goal)
  const [currentOptimizationGoals, setCurrentOptimizationGoals] = useState<any[]>(convertObjectToArray(EngagementWebsiteConversionOptimizationGoals))

  useEffect(() => {

    if (campaign?.objective?.toLowerCase().includes("awareness")) {
      setCurrentOptimizationGoals(convertObjectToArray(AwarenessOptimizationGoals))
    }
    if (campaign?.objective?.toLowerCase().includes("traffic")) {
      setCurrentOptimizationGoals(convertObjectToArray(TrafficOptimizationGoals))
    }

  }, [campaign?.objective])


  const handleOptimizationGoalChange = (e: SelectChangeEvent<any>) => {
    const oGoal = e.target.value
    setOptimizationGoal(oGoal)
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => onCloseDialog(false)}
    >
      <DialogTitle>
        Ad Set Optimization Goal Edit
      </DialogTitle>
      <DialogContent>
        <Grid container rowSpacing={4} minWidth={800} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

          <Grid item xs={12}>
            <Typography variant='h6' sx={{mb: 1, ...desktop_h4}}>Current Campaign</Typography>
            <Typography sx={{...desktop_p3}}>Name: {campaign?.name}</Typography>
            <Typography sx={{...desktop_p3}}>Objective: {campaign?.objective}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider/>
          </Grid>

          <Grid item xs={6}>
            <Typography variant='h6' sx={{mb: 1.5, ...desktop_h4}}>Update Optimization Goal</Typography>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel id="optimization-goal-select-label">Optimization goal</InputLabel>
              <Select
                labelId="optimization-goal-select-label"
                id="optimization-goal"
                value={optimizationGoal}
                onChange={handleOptimizationGoalChange}
                label="Optimization goal"
              >
                {currentOptimizationGoals.map((og: any) => {
                  return <MenuItem sx={{ fontSize: '14px' }} key={og} value={og}>{beautifyString(og)}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions sx={{mt: 2, mb: 1.5}}>
        <Button variant="contained" color="primary" onClick={() => onCloseDialog(false)}>Cancel</Button>
        <Button variant="contained" color="error" onClick={() => {
          onCloseDialog(true, { optimization_goal: optimizationGoal })
        }}>Update</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AdSetOptimizationGoalEditDialog;