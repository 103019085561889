import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { desktop_h1 } from '../../styles/textStyles'
import { DatePickerMonthYear } from '../DatePickerMonthYear'
import { regularBtnStyles } from '../../styles/buttons'
import { useCustomerState } from '../../context/customer/CustomerContext'
import { apiGetKeywordPlan, KeywordPlanResponse } from '../../api/KeywordPlanner'
import dayjs from 'dayjs'
import KeywordPlannerTable from './KeywordPlannerTable'
import { useSharedStateValue } from '../../context/shared/SharedContext'

const computeCalcValue = (calcExpression: any) => {
  const tempElement = document.createElement('div');
  tempElement.style.width = calcExpression;
  document.body.appendChild(tempElement);
  const computedValue = window.getComputedStyle(tempElement).width;
  document.body.removeChild(tempElement);
  return computedValue;
}

const KeywordPlanner = () => {
  const customerState = useCustomerState()
  const { selected } = customerState
  const now = new Date();
  const [startDate, setStartDate] = useState<Date>(new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()))
  const [endDate, setEndDate] = useState<Date>(now)

  const [userInput, setUserInput] = useState<string>('')
  const [keywords, setKeywords] = useState<string[]>([])

  const [sendBtnPropress, setSendBtnProgress] = useState(false)

  const [keywordPlan, setKeywordPlan] = useState([])
  const [yearMonths, setYearMonths] = useState<string[]>([])

  const [{ drawerIsOpen }, sharedDispatch] = useSharedStateValue()
  const [maxWidth, setMaxWidth] = useState(computeCalcValue('calc(100vw - 360px)'));


  useEffect(() => {
    if (drawerIsOpen) {
      setMaxWidth(computeCalcValue('calc(100vw - 360px)'));
    } else {
      setMaxWidth(computeCalcValue('calc(100vw - 164px)'));
    }
  }, [drawerIsOpen]);


  useEffect(() => {
  }, [keywordPlan, yearMonths])
  
  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (date) setStartDate(date.toDate())
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    if (date) setEndDate(date.toDate())
  };

  const handleUserInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = event.target.value;
    setUserInput(input);

    const keywordArray = input.split(/[\n,]+/) // Split by newlines or commas
      .map(keyword => keyword.trim()) // Trim whitespace
      .filter(keyword => keyword); // Remove empty strings

    setKeywords(keywordArray);
  };

  const handleSendButton = () => {
    if (selected?.id && keywords.length > 0 && startDate && endDate) {
      setSendBtnProgress(true)
      apiGetKeywordPlan(selected.id, { keywords, startDate, endDate }).then((res: KeywordPlanResponse) => {
        setKeywordPlan(res.plan)
        setYearMonths(res.yearMonths)
        setSendBtnProgress(false)
      }).catch((err: any) => {
        console.log("Error: KeywordPlanner: apiGetKeywordPlan: ", err)
        setSendBtnProgress(false)
      })
    }

  }
  return (
    <Box sx={{ margin: '50px', display: 'flex', maxWidth: maxWidth }}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '36px' }}>
        <Box sx={{ width: '50%' }}>
          <Typography variant='h1' sx={desktop_h1}>Keyword Planner</Typography>
        </Box>
        {/* Date  */}
        <Box sx={{ alignItems: 'center', minWidth: '280px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
            <DatePickerMonthYear label={'Start date'} date={startDate} onChange={handleStartDateChange} onClose={() => ''} />
            <DatePickerMonthYear label={'End date'} date={endDate} onChange={handleEndDateChange} onClose={() => ''} />
          </Box>
        </Box>
        {/* Keywords  */}
        <Grid container sx={{ maxWidth: 600, mt: '24px' }}>
          <Grid item xs={12} sx={gridItemStyles}>
            <Typography sx={{ fontWeight: 600, mb: 1 }}>Keywords</Typography>
            <TextField placeholder={'Add keywords, one word or phrase per line or separated by commas.'} fullWidth multiline maxRows={6} minRows={4} size='small' variant="outlined" value={userInput} onChange={handleUserInputChange} />
          </Grid>
          {/* Send btn  */}
          <Grid item xs={12} sx={{ mt: '16px' }}>
            <Button sx={{ ...regularBtnStyles, mt: 1 }} onClick={handleSendButton} disabled={keywords.length > 0 ? false : true}>
              {sendBtnPropress ? <CircularProgress style={{ width: '20px', height: '20px', color: "#fff" }} /> : 'Send'}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: '24px' }}>
          <Grid container>
          <KeywordPlannerTable keywordPlan={keywordPlan} yearMonths={yearMonths} loading={sendBtnPropress}/>
          </Grid>
        </Grid>
      </Grid>
    </Box>)
}


const gridItemStyles = {
  mt: 0,
  mb: 0
}

export default KeywordPlanner