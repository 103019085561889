import { IdNameObj } from "../../api/Types"
import { CompetitorAction, CompetitorAdUnits, CompetitorSettings, SET_COMPETITOR_AD_UNITS, SET_COMPETITOR_FACEBOOK_PAGES, SET_COMPETITOR_SETTINGS, SET_GOOGLE_ADVERTISERS } from "./CompetitorTypes"

export const setCompetitorAdUnits = (competitorAdUnits: CompetitorAdUnits) : CompetitorAction => {
  return { type: SET_COMPETITOR_AD_UNITS, data: competitorAdUnits }
}

export const setCompetitorSettings = ( settings: CompetitorSettings ) : CompetitorAction => {
  return { type: SET_COMPETITOR_SETTINGS, data: settings }
}

export const setCompetitorFacebookPages = (facebookPages: IdNameObj[]) : CompetitorAction => {
  return { type: SET_COMPETITOR_FACEBOOK_PAGES, data: facebookPages }
}

export const setGoogleAdvertisers = ( advertisers: IdNameObj[] ) : CompetitorAction => {
  return { type: SET_GOOGLE_ADVERTISERS, data: advertisers }
}