import { MediaCategory, MediaType } from "../components/AdTemplateView/Types"
import { TotalCreativeSpendsResponse } from "./Creative"

export const apiRootUrl = process.env.REACT_APP_API_ROOT_URL || 'undefined'
export const rootUrl = process.env.REACT_APP_ROOT_URL || 'undefined'
export type CustomerRole = "admin" | "viewer" | "subscriptionAdmin"

export interface CustomerTree {
  id: number
  name: string
  roles: CustomerRole[]
  children: CustomerTree[]
  services: string[]
}

export interface SpendTree {
  open: boolean
  customerId: string
  name: string
  spendReport?: TotalCreativeSpendsResponse
  children: SpendTree[]
}

export interface ConversionPredictions {
  hourlyForecasts: HourlyConversions[],
  hourlyActuals: HourlyConversions[],
  hourlyGoals: HourlyConversions[]
}

export type ConversionPredictionList = { tag: string, name: string }[]

export type AuthenticatedUser = {
  name: string
  email: string
  picture: string
  identityProvider: string // (auth0, google-oauth2...)
  // Token is still required if you want to use the old way to log in
  token?: string
}

export type LoginResponse = {
  email: string
  name: string
  picture: string
  identityProvider: string
}

export const noAuthenticatedUser: AuthenticatedUser = {
  name: '',
  email: '',
  token: '',
  picture: '',
  identityProvider: ''
}

export interface HourlyConversions {
  date: string    // YYYY-mm-DD
  total: number   // 0...
  hour: number    // 0-23
  month: number   // 1-12
  day: number     // 1-31
  weekday: number // 1-7
  week: number    // 1-53
}

export type OAuthSystem = "displayvideo" | "meta" | "searchConsole" | "googleAnalytics"

export interface OAuthLoginResponse {
  url: string
}

export type ConversionDetails = {
  [actionType: string]: number,
  total: number
}

export type Creative = {
  id: string;
  title: string;
  channel: string
  headlines: string[];
  descriptions: string[]
  paths: string
  category: string
  url: string;
  width: number
  height: number
  status: CreativeStatus
  clicks?: number
  impressions?: number
  cost?: number
  totalConversions?: number
  mobileTotalConversions?: number
  tableTotalConversions?: number
  desktopTotalConversions?: number
  postClickConversions?: number
  postViewConversions?: number
  ctr?: number
  landingPageUrl?: string
  externalUrls?: string[],
  totalConversionDetails?: ConversionDetails,
  desktopConversionDetails?: ConversionDetails,
  mobileConversionDetails?: ConversionDetails
}

export type CreativeResponse = {
  start: number,
  total: number,
  campaigns: CampaignCreative[]
  adGroups: AdGroupCreative[]
  floodlightActivities: string[]
  creatives: Creative[],
  dimensions: { [index: string]: number }
  categoriesImpressions: { [category: string]: number } // total impr for each category
}

export type CampaignCreative = {
  id: string
  title: string
}

export type AdGroupCreative = {
  id: string
  title: string
  campaignId: string
}

export type CreativeStatus = "Active" | "Paused" | "Archived" | "Unknown"

export type AdUnitSettings = {
  dv360: {
    partnerId?: string;
    advertiserIds: string[]
    allowEdit?: boolean
  }
}

export type GoogleAnalyticsSettings = {
  accountIds: string[]
}

export type DBCampaign = {
  id: string,
  adSet: DBAdset
}

export type DBAdset = {
  id: string,
  ads: string[],
  keywords: string[],
  adCreatives: string[]
}

export type MetaSettingsMarketing = {
  campaigns: DBCampaign[],
}

export type MetaSettings = {
  facebookPageId: string;
  adAccountId: string;
  marketing?: MetaSettingsMarketing
  notifyAuthExpirationDays?: number;
  competitorFacebookPages?: { id: string, name: string }[]
}

export type SearchConsoleSettings = {
  websiteUrls: string[]
}

export type IdNameObj = {
  id: string,
  name: string
}

export type GoogleAdsSettings = {
  adAccounts: IdNameObj[]
  loginAccount: IdNameObj
  childCustomers: { id: string, name: string, googleAdsSettings: GoogleAdsSettings }[]
}

export type BingAdsSettings = {
  customerId: string
  accountIds: string[]
}

export type KeywordVolume = {
  searchKeyword: string;
  resultKeyword: string;
  searchVolume: number;
  costPerClick: number;
  competition: number;
  numberOfResults: number;
  trends: number[];
};

export type PageKeywordStatResponse = {
  websiteUrl: string
  stats: PageKeywordStat[]
}

export type KeywordVolumesResponse = {
  keywords: string[]
  volumes: KeywordVolume[]
}

export type PageKeywordStat = {
  hidden?: boolean
  category?: string
  keyword: string
  landingPage?: string
  clicks: number
  impressions: number
  ctr: number
  position: number

  // Keyword statistics from secondary smart sources
  searchVolume?: number
  cpc?: number
  competition?: number
  numberOfResults?: number,
  trends?: number[]
}

export type KeywordCategoriesResponse = {
  categories: { keyword: string, relatedKeywords: PageKeywordStat[] }[]
}

export type UsersResponse = { name: string, email: string }[]


export type NotificationRule = {
  type: "NotificationRule"
  channel: NotificationChannel
  attribute: NotificationAttribute | MetaNotificationAttribute
  value: number
  operator: Operator
  interval: Interval
  receivers: string[]
}

export type NotificationRulesResponse = NotificationRule[]

export type NotificationChannel = "dv360Ads" | "googleAds" | "metaAds" | "bingAds" | "readpeakAds"

export type NotificationAttribute = 'impressions' | 'clicks' | 'spend'

export type MetaNotificationAttribute = NotificationAttribute | 'postBoosterAds'

export type Operator = 'smallerThan' | 'greaterThan'

export type Interval = 'Hourly' | 'Daily' | 'Weekly' | 'Monthly' | 'OnAdCreationSuccessOrFailure'

export type ServiceTypes = 'KivaAnalytics' | 'KivaCss' | 'KivaAdAutomations' | 'KivaPostBooster' | 'KivaAds' | 'SalesPredictor' |
  'KeywordAnalysis' | 'ManagedAds' | 'DigitalMarketingAssistant' | 'AdTemplates' | 'Analytics' | 'CustomAssistant' | 'Files' | 'CompetitorAds'

export type KivaAnalyticsConfiguration = {
  type: "KivaAnalyticsConfiguration"
  googleAnalytics4Id?: string
  kivaAnalyticsId?: string
}

export type ServiceConfiguration = KivaAnalyticsConfiguration | {}

export type CustomerService = {
  type: ServiceTypes
  logoLink?: string
  title: string
  description: string
  link: string,
  directOrder?: boolean
  active?: boolean
  configuration: ServiceConfiguration
}

export type ManagedAdType = 'HTML' | 'Image' | 'Video'

export type ManagedAdPlatformTypes = "DisplayVideo" | "Meta"

export type ManagedAdPlatformDisplayVideo = {
  id: string
  type: "DisplayVideo",
}

export type ManagedAdPlatformMeta = {
  id: string
  type: "Meta",
  adType: "Video" | "Image"
  message?: string
  url?: string
  campaignId?: string
  adsetId?: string
  adId?: string
  adName?: string,
  width?: number
  height?: number
}

export type ManagedAdPlatform = ManagedAdPlatformDisplayVideo | ManagedAdPlatformMeta

export type ManagedAdDataType = 'Static' | 'Dynamic'
export type ManageAdDataMapping = {
  templateParameter: string
  type: ManagedAdDataType
  path?: string
  value?: string
}

export type ManagedAdDataSource = {
  key: string
  description: string
  value: string | number
  unit: string
}

export type ManagedAdExitEvent = {
  name: string;
  url: string;
  adSizes: AdSize[];
}

export type ManagedAdFile = {
  name: string
  width: number
  height: number
  filename: string
  url: string
}

export type ManagedAdInstance = {
  width: number
  height: number,
  previewUrl: string
}

export type ManagedAdUpdate = {
  type: 'daily'
  hour: number  // 0-23
  minute: number // 0-59
}


export type ManagedAdTypeDef = {
  type: ManagedAdType
  useHtmlBase: boolean
  entryPoint?: string
  durationInMs?: number
}

export type ManagedAd = {
  name: string
  types: ManagedAdTypeDef[]
  creativeId: string
  entryPoint: string
  previewUrl: string
  dataMappings: ManageAdDataMapping[];
  platforms: ManagedAdPlatform[];
  exitEvents: ManagedAdExitEvent[]
  instances: ManagedAdInstance[]
  rules: ManagedAdRule[]
  synchronize: boolean
  updates: ManagedAdUpdate;
  templateId?: string
}

export type ManagedAdRuleOperation = 'Activate' | 'Pause' | 'None'

export type ActivationRuleType = 'Threshold' | 'Interval'

export type ThresholdManagedAdActivationRule = {
  type: 'Threshold'
  operationBelow: ManagedAdRuleOperation
  operationAbove: ManagedAdRuleOperation
  value: number
  datasourceKey: string
}

export type IntervalManagedAdActivationRule = {
  type: 'Interval'
  operationBelow: ManagedAdRuleOperation
  operationAbove: ManagedAdRuleOperation
  operationBetween: ManagedAdRuleOperation
  valueMin: number
  valueMax: number
  datasourceKey: string
}

export type ManagedAdRule = ThresholdManagedAdActivationRule | IntervalManagedAdActivationRule

export type NewUser = {
  name: string;
  email: string;
  reqCustomerId: number | string
  createdCredentials?: {
    email: string,
    password: string
  }
}

export type CreateNewUserRes = {
  success: boolean,
  createdCredentials?: {
    email: string,
    password: string
  },
  message?: string
}

export type PredictionScenario = {
  id: string
  name: string
  year: number
  weeklyBudgets: number[]
  weeklyGoals: number[]
}

export type SpendsSettings = {
  host: string
  username: string
  directory: string
  publicKey: string
  privateKey: string
  channels: string[]
}

export type LinkedInAdsSettings = {
  adAccount: { id: string, name: string, type: string }
}

export type YoutubeContentSettings = {
  loginAccountId?: string,
  contentOwnerIds: string[]
}

export type SlackSettings = {
  channels: string[]
}

export type CustomerDataset = {
  projectId: string
  datasetId: string
  type: "GoogleAnalytics4",
  conversionEvents: string[]
}

export type DatasetSettings = CustomerDataset[]

export type ReadpeakAdsSettings = {
  apiKey: string,
  clientNames: string[]
}

export type AdTemplateFileImage = {
  id: string;
  type: MediaType;
  category: MediaCategory
  name: string;
  filename: string;
  width: number;
  height: number;
}

export type AdTemplateFileFont = {
  id: string;
  type: MediaType
  category: MediaCategory
  name: string;
  filename: string;
}

export type AdTemplateFileHtml = {
  id: string;
  type: "html";
  category: MediaCategory
  name: string;
  filename: string;
  width: number;
  height: number;
}

export type AdSize = {
  width: number
  height: number
}

export type AdTemplateFile = AdTemplateFileImage | AdTemplateFileFont | AdTemplateFileHtml

export type MetaCampaign = {
  id: string
  name: string
  status: 'PAUSED' | 'ACTIVE'
}

export type MetaAdSet = {
  id: string
  name: string
  status: 'PAUSED' | 'ACTIVE'
}

export type AttributionType = 'FirstClick' | 'LastClick' | 'TimeDecay'

export type AggregationPeriod = 'date' | 'week' | 'month'

export type Attribution = {
  type: AttributionType
  conversionType: string
  date?: string
  channel?: string
  channelName?: string
  campaignName?: string
  adName?: string
  revenue: number
  count: number
  cumulativeRevenue: number
  cumulativeCount: number
  estimated: boolean
}

export type CustomDatasetRow = {
  id: string
  question: string
  answer: string
}

export type CustomDataset = {
  type: 'QuestionAnswer'
  id: string
  name: string
  rowStartIndex?: number
  rows?: CustomDatasetRow[]
}

export type CustomDatasetPage = {
  url: string
  title?: string
  description?: string
}

export type CustomDatasetPagesResponse = {
  total: number
  previewPages: CustomDatasetPage[]
}

export type QuestionAnswer = {
  question: string
  answer: string
}

export type CustomDatasetQuestionAnswerResponse = QuestionAnswer[]

export type PageFilters = {
  urls: string[]
  title: string
  titlePostfix: string
  description: string
}

export type FaqFilters = {
  question: string
  answer: string
}

export type CustomModelTaskStatus = "Unknown" | "Waiting" | "Running" | "Succeeded" | "Failed" | "Cancelled" | "Paused"

export type CustomModelTask = {
  id: string
  type: 'Teach'
  created: string
  updated: string
  externalId: string
  customDatasetIds: string[]
  status: CustomModelTaskStatus
}

export type CustomModelType = 'PaLM2' | 'Pena'

export type CustomModel = {
  id: string
  type: CustomModelType
  name: string
  baseModel: string
  tasks: CustomModelTask[]
}

export type ChatMessage = {
  role: 'assistant' | 'user'
  content: string
}

export type Visitor = {
  date?: string
  channel?: string
  channelName?: string
  campaignName?: string
  count: number
}

export type VisitorChannel = {
  channel?: string
  channelName?: string,
  count?: number
}

export type VisitorFilters = {
  channels: VisitorChannel[]
  landingPageTitles: { landingPageTitle: string, count: number }[]
  campaignNames: { campaignId: string, campaignName: string, count: number }[]
  conversionTypes: string[]
}

export type ComparisonPeriod = "Custom" | "Week" | "Month" | "Year"

export type FileType = "Folder" | "File"

export type FileTree = {
  id: string
  type: FileType
  label: string
  description: string
  category: string
  subCategory: string
  size?: number
  storagePath?: string
  width?: number
  height?: number
  selected?: boolean
  children: FileTree[]
}

export const findFromFileTree = (id: string, tree: FileTree): string | null => {
  if (tree.id === id) {
    return id
  }
  for (const child of tree.children) {
    const childId = findFromFileTree(id, child)
    if (childId) {
      return childId
    }
  }
  return null
}