import React from 'react'
import { Divider, AccordionActions, Button, Grid, Tooltip, IconButton, TextField, Box } from '@mui/material';
import { Colors } from '../../../constants/colors'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStateValue } from '../../../context/meta/MetaContext';
import { setAdAutomationSettings, setAdAutomations, setPostBoostingRules } from '../../../context/meta/MetaActions';
import { deleteAdAutomation, getFullAdAutomationById, updateAdAutomation, updateAdAutomationBudget, updateAdAutomationDefaultCityRadius, updateAdAutomationKeywords, updateAdAutomationOptimizationGoal, updateAdAutomationTargeting } from '../../../api/Marketing';
import { gendersToString, arrayOfStringsToString, convertToFinnishDate, capitalizeFirstLetter, beautifyString } from '../../../utils/helpers';
import { useAlertStateValue } from '../../../context/alert/AlertContext';
import { setAlert } from '../../../context/alert/AlertAction';
import { AlertSeverity } from '../../../context/alert/AlertTypes';
import { redBtn, svg16Styles, whiteBtnBlackBorder } from '../../../styles/buttons'
import { DBAdAutomation, EntireAdAutomation, MetaObjType } from '../../../context/meta/MetaTypes';
import { initialFullAdAutomation } from '../../../context/meta/MetaReducer';
import IOSSwitchComponent from '../sharedMetaComponents/IOSSwitchComponent';
import DeleteIcon from '@mui/icons-material/Delete';
import { deltePostBoostingRule, updatePostBoostingRule } from '../../../api/Posts';
import { Edit } from '@mui/icons-material';
import AdSetTargetingDialog from './AdSetTargetingDialog';
import { debouncePromise } from '../../../utils/Generators';
import { desktop_h3 } from '../../../styles/textStyles';
import SaveIcon from '@mui/icons-material/Save';
import DeleteDialog from '../../DeleteDialog';
import AdSetOptimizationGoalEditDialog from './AdSetOptimizationGoalEditDialog';

interface SingleMetaAccordionProps {
  metaObj: DBAdAutomation | any,
  metaObjType: MetaObjType
  customerId: number | undefined
}

const debouncedUpdateAdAutomationDefaultCityRadius = debouncePromise(updateAdAutomationDefaultCityRadius)
const debouncedUpdateAdAutomationKeywords = debouncePromise(updateAdAutomationKeywords)

const SingleMetaAccordion = ({ metaObj, metaObjType, customerId }: SingleMetaAccordionProps) => {

  const [{ adAutomations, postBoostingRules, adAutomationSettings }, dispatch] = useStateValue()
  const [editDefaultCityRadius, setEditDefaultCityRadius] = React.useState<boolean>(false);
  const [editKeywords, setEditKeywords] = React.useState<boolean>(false)
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [entireMetaObj, setEntireMetaObj] = React.useState<EntireAdAutomation | any>(metaObjType === MetaObjType.Ad_Automation ? initialFullAdAutomation : metaObj);
  const [checked, setChecked] = React.useState(metaObj.active)
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [optimizationGoalDialogOpen, setOptimizationGoalDialogOpen] = React.useState<boolean>(false)

  const [budget, setBudget] = React.useState<number>(metaObj.budgetProps?.budget)
  const [editBudget, setEditBudget] = React.useState(false)
  const [editOptimizationGoal, setEditOptimizationGoal] = React.useState(false)

  const [monthlyBudget, setMonthlyBudget] = React.useState<number>(metaObj.monthlyBudget)
  const [editMonthlyBudget, setEditMonthlyBudget] = React.useState(false)

  const handleSwitchClick = async (e: any) => {

    const newValue = !checked
    setChecked(newValue)
    const fieldsToBeUpdated = {
      active: newValue
    }

    if (metaObjType === MetaObjType.Ad_Automation) {
      if (customerId) {
        const result = await updateAdAutomation(customerId, metaObj.id, fieldsToBeUpdated)
        if (!result.success) {
          setChecked(!newValue)
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: result.message, duration: null }))
        }
      }
    } else {
      const updatedRule = { ...metaObj, active: newValue }
      const updatedRules = postBoostingRules.map(r => r.id === metaObj.id ? updatedRule : r)
      dispatch(setPostBoostingRules(updatedRules))
      if (customerId) {
        const result = await updatePostBoostingRule(customerId, metaObj.id, fieldsToBeUpdated)
        if (!result.success) {
          setChecked(!newValue)
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: result.message, duration: null }))
        }
      }
    }

  }

  React.useEffect(() => {
    if (expanded && metaObjType === MetaObjType.Ad_Automation) {
      customerId && getFullAdAutomationById(customerId, metaObj.id).then((result) => {
        setEntireMetaObj(result)
      }).catch(error => {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: error.message, duration: null }))
      });
    }

    if (expanded && metaObjType === MetaObjType.POST_BOOSTING_RULE) {
      setEntireMetaObj(metaObj)
    }
  }, [expanded])

  const toggleAccordion = (_event: React.SyntheticEvent) => {
    setExpanded(!expanded);
  };


  const handleDelete = async (_event: React.SyntheticEvent) => {
    if (customerId) {
      setExpanded(false)
      if (metaObjType === MetaObjType.Ad_Automation) {
        await deleteAdAutomation(customerId, metaObj.id)
        dispatch(setAdAutomations(adAutomations.filter((i: any) => i.id.toString() !== metaObj.id.toString())))
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Ad automation deleted successfully!", duration: 7000 }))
      } else {
        const result = await deltePostBoostingRule(customerId, metaObj.id)
        if (result === 204) {
          dispatch(setPostBoostingRules(postBoostingRules.filter(r => r.id !== metaObj.id)))
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "The rule deleted successfully!", duration: 7000 }))
        } else {
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Something went wrong. ${result.message}`, duration: null }))
        }
      }
    }
  }

  const handleDefaultCityRadiusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const radius = parseInt(event.target.value, 10)
    if (radius > 0 && radius < 500 && customerId) {
      setEntireMetaObj({ ...entireMetaObj, adSetModel: { ...entireMetaObj.adSetModel, defaultCityRadius: radius } })
      debouncedUpdateAdAutomationDefaultCityRadius(customerId, metaObj.id, radius).then(() => {
      }).catch(() => {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Update failed.`, duration: null }))
      })
    }
  }

  const handleKeywordsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keywords = event.target.value.split(",")
    if (customerId) {
      setEntireMetaObj({ ...entireMetaObj, keywords })
      debouncedUpdateAdAutomationKeywords(customerId, metaObj.id, keywords).then(() => {
      }).catch(() => {
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Update failed.`, duration: null }))
      })
    }
  }

  const handleBudgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBudget(Number(event.target.value))
  }
  const handleMonthlyBudgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonthlyBudget(Number(event.target.value))
  }

  const handleEditBudgetClick = () => {
    if(customerId && editBudget){
      const data = {
        isMonthlyBudget: false,
        budget: budget
      }
      updateAdAutomationBudget(customerId, metaObj.id, data).then((res) => {
        if(res.status === 200){
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: `Budget updated.`, duration: 7000 }))
        }
      }).catch((error) => {
        console.log("handleEditBudgetClick error", error)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Budget update failed`, duration: null }))
      })
    }
    setEditBudget(!editBudget)
  }

  const handleEditMonthlyBudgetClick = () => {
    if(customerId && editMonthlyBudget){
      const data = {
        isMonthlyBudget: true,
        budget: monthlyBudget
      }
      updateAdAutomationBudget(customerId, metaObj.id, data).then((res) => {
        if(res.status === 200){
          alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: `Monthly budget updated.`, duration: 7000 }))
        }
      }).catch((error) => {
        console.log("handleEditMonthlyBudgetClick error", error)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Budget update failed`, duration: null }))
      })
    }
    setEditMonthlyBudget(!editMonthlyBudget)
  }

  const dynamicDays = entireMetaObj?.adSetModel?.dynamicDays

  return (
    <Accordion key={metaObj?.id} expanded={expanded} onChange={toggleAccordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        {/* Ad automation details: name, status and createdAt */}
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={6} onChange={() => setExpanded(false)}>
            <Typography sx={{}}>
              <IOSSwitchComponent checked={checked} setChecked={handleSwitchClick} />
              <span>{metaObj?.name}</span>
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Typography sx={{}}>{convertToFinnishDate(metaObj?.createdAt)}</Typography>
          </Grid>
          <Grid item xs={3} sx={{}}>
            <Box onClick={(event) => event.stopPropagation()}>
              <DeleteDialog title={"Delete Automation"} text={<>Are you sure you would like to delete the automation named "<b>{metaObj?.name}</b>"?</>} handleDeleteClick={handleDelete}/>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      {metaObjType === MetaObjType.Ad_Automation && <AccordionDetails>
        <Typography variant='h3' sx={{...desktop_h3, margin: '10px auto'}}>
          Automation
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              Max number of active ads
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.maxNumberOfActiveAds}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              Active ads
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.activeAds !== undefined ? entireMetaObj?.activeAds : 'unknown'}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: '16px' }} />
      </AccordionDetails>}

      {/* Campaign details */}
      <AccordionDetails>
        <Typography variant='h3' sx={{...desktop_h3, margin: '10px auto'}}>
          Campaign details
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              Name
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.campaign?.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              Status
            </Typography>
            <Typography style={styles.caption} variant='caption' sx={{ color: entireMetaObj?.campaign?.status === "PAUSED" ? Colors.yellow : Colors.green }}>
              {entireMetaObj?.campaign?.status && capitalizeFirstLetter(entireMetaObj.campaign.status)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Objective
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.campaign?.objective && beautifyString(entireMetaObj.campaign.objective)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container sx={{ margin: '16px auto' }}>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              Created time
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.campaign?.created_time && convertToFinnishDate(entireMetaObj.campaign.created_time)}
              {!entireMetaObj?.campaign?.created_time && '-'}
            </Typography>
          </Grid>
          {metaObj.budgetProps && metaObj.budgetProps?.budgetLevel === "campaign_level" && <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              {metaObj.budgetProps?.budget_type === "lifetime" ? "Lifetime budget" : "Daily budget"} <Tooltip placement="top" title={editBudget ? "Save" : "Edit"}>
                <IconButton onClick={handleEditBudgetClick}>{editBudget ? <SaveIcon sx={{...svg16Styles}}/> : <Edit sx={{...svg16Styles}} />}</IconButton>
              </Tooltip>
            </Typography>
            {editBudget && <Typography style={styles.caption} variant='caption' sx={{}}>
              <TextField variant="standard"
                onChange={handleBudgetChange}
                type="number" value={budget} />
            </Typography>}
            {!editBudget && <Typography style={styles.caption} variant='caption'>
              {budget}€
            </Typography>}
          </Grid>}

          {metaObj.monthlyBudget && <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              Monthly budget  <IconButton onClick={handleEditMonthlyBudgetClick}>{editMonthlyBudget ? <SaveIcon sx={{...svg16Styles}}/> : <Edit sx={{...svg16Styles}} />}</IconButton>
            </Typography>
            {editMonthlyBudget && <Typography style={styles.caption} variant='caption' sx={{}}>
              <TextField variant="standard"
                onChange={handleMonthlyBudgetChange}
                type="number" value={monthlyBudget} />
            </Typography>}
            {!editMonthlyBudget && <Typography style={styles.caption} variant='caption'>
              {monthlyBudget}€
            </Typography>}
          </Grid>}

          {(entireMetaObj?.campaign?.lifetime_budget || entireMetaObj?.campaign?.daily_budget) && <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              {entireMetaObj?.campaign?.daily_budget && Number(entireMetaObj.campaign.daily_budget) > 0 ? 'Daily budget' : 'Lifetime budget'}
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.campaign?.daily_budget && Number(entireMetaObj.campaign.daily_budget) > 0 ? `${Number(entireMetaObj.campaign.daily_budget) / 100} €` : `${entireMetaObj?.campaign?.lifetime_budget && Number(entireMetaObj.campaign?.lifetime_budget) / 100} €`}
            </Typography>
          </Grid>}

          {entireMetaObj?.campaign?.bid_strategy && <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              Bid strategy
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.campaign?.bid_strategy && beautifyString(entireMetaObj.campaign.bid_strategy)}
            </Typography>
          </Grid>}
        </Grid>
        <Divider sx={{ marginTop: '16px' }} />
      </AccordionDetails>


      {/* Ad set details */}
      <AccordionDetails>
        <Typography variant='h3' sx={{...desktop_h3, margin: '10px auto'}}>
          Ad set details
        </Typography>
        <Grid container>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Name
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.adSetModel?.name}
            </Typography>
          </Grid>

          {!dynamicDays && <Grid item xs={2}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Start time
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.adSetModel?.start_time && convertToFinnishDate(entireMetaObj.adSetModel.start_time)}
            </Typography>
          </Grid>}
          {!dynamicDays && <Grid item xs={2}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              End time
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.adSetModel?.end_time && convertToFinnishDate(entireMetaObj.adSetModel.end_time)}
              {!entireMetaObj?.adSetModel?.end_time && 'Ongoing'}
            </Typography>
          </Grid>}
          {dynamicDays && <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Advertising period
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {dynamicDays} {dynamicDays > 1 ? 'days' : 'day'} from post creation
            </Typography>
          </Grid>}
        </Grid>

        <Grid container sx={{ margin: '16px auto' }}>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Optimization goal
              <Tooltip placement="top" title={editOptimizationGoal ? "Save" : "Edit"}>
                <IconButton onClick={() => setOptimizationGoalDialogOpen(true)}>{editOptimizationGoal ? <SaveIcon sx={{...svg16Styles}}/> : <Edit sx={{...svg16Styles}} />}</IconButton>
              </Tooltip>
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.adSetModel?.optimization_goal && beautifyString(entireMetaObj.adSetModel.optimization_goal)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Billing event
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.adSetModel?.billing_event && beautifyString(entireMetaObj.adSetModel.billing_event)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container sx={{ margin: '16px auto' }}>
          {entireMetaObj.budgetProps && metaObj.budgetProps?.budgetLevel === "ad_set_level" && <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2'>
              {metaObj.budgetProps?.budget_type === "lifetime" ? "Lifetime budget" : "Daily budget"}
              {dynamicDays ? ' (per post)' : ''}
              <Tooltip placement="top" title={editBudget ? "Save" : "Edit"}>
                <IconButton onClick={handleEditBudgetClick}>{editBudget ? <SaveIcon sx={{...svg16Styles}}/> : <Edit sx={{...svg16Styles}} />}</IconButton>
              </Tooltip>
            </Typography>
            {editBudget && <Typography style={styles.caption} variant='caption' sx={{}}>
              <TextField variant="standard"
                onChange={handleBudgetChange}
                type="number" value={budget} />
            </Typography>}
            {!editBudget && <Typography style={styles.caption} variant='caption'>
              {budget}€
            </Typography>}
          </Grid>}

          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Bid Strategy
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {`${entireMetaObj?.adSetModel?.bid_strategy && beautifyString(entireMetaObj.adSetModel.bid_strategy)}`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Bid amount
            </Typography>
            <Typography style={styles.caption} variant='caption'>
              {`${entireMetaObj?.adSetModel?.bid_amount ? (entireMetaObj.adSetModel.bid_amount) + ' €' : ''}`}
              {`${!entireMetaObj?.adSetModel?.bid_amount ? 'Automatic' : ''}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ margin: '16px auto' }}>
          <Grid item xs={4}>
            <Typography style={styles.body2} variant='body2' sx={{}}>
              Default city radius <IconButton onClick={() => setEditDefaultCityRadius(!editDefaultCityRadius)}><Edit sx={{...svg16Styles}} /></IconButton>
            </Typography>
            {editDefaultCityRadius && <Typography style={styles.body2} variant='body2' sx={{}}>
              <TextField variant="standard" label="City radius(km)" id="radius-input"
                onChange={handleDefaultCityRadiusChange}
                type="number" value={entireMetaObj?.adSetModel?.defaultCityRadius} />
            </Typography>}
            {!editDefaultCityRadius && <Typography style={styles.caption} variant='caption'>
              {entireMetaObj?.adSetModel?.defaultCityRadius}&nbsp;km
            </Typography>}
          </Grid>
        </Grid>


        {/* Ad set targeting */}
        <Grid container sx={{ marginTop: '24px' }}>
          <Grid item xs={12}>
            <Typography style={styles.body1} variant='body1' sx={{}}>
              Ad set targeting  <IconButton onClick={() => setDialogOpen(!optimizationGoalDialogOpen)}><Edit sx={{...svg16Styles}} /></IconButton>
            </Typography>
          </Grid>

          <Grid container sx={{}}>
            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Saved audience
              </Typography>
              <Typography style={styles.caption} variant='caption' sx={{}}>
                {entireMetaObj?.adSetModel?.audienceInfo?.savedAudience ?
                  `${entireMetaObj?.adSetModel?.audienceInfo?.savedAudienceName} (id: ${entireMetaObj?.adSetModel?.audienceInfo?.savedAudienceId})` : 'Not in use'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Age
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.age_min} - {entireMetaObj?.adSetModel?.targeting?.age_max}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Genders
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.genders && gendersToString(entireMetaObj.adSetModel.targeting.genders)}
                {!entireMetaObj?.adSetModel?.targeting?.genders && "Male, Female"}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ margin: '16px auto' }}>
            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Countries
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.geo_locations?.countries?.length > 0 && entireMetaObj.adSetModel.targeting.geo_locations.countries.map((c: string) => c).join(', ')}
                {!entireMetaObj?.adSetModel?.targeting?.geo_locations?.countries && "-"}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Cities
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.geo_locations?.cities?.length > 0 && entireMetaObj.adSetModel.targeting.geo_locations.cities.map((c: any) => `${c.name}(${c.radius}km)`).join(', ')}
                {!entireMetaObj?.adSetModel?.targeting?.geo_locations?.cities && "-"}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Zip codes
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.geo_locations?.zips?.length > 0 && entireMetaObj.adSetModel?.targeting?.geo_locations?.zips.map((z: string) => z).join(', ')}
                {!entireMetaObj?.adSetModel?.targeting?.geo_locations?.zips && "-"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ margin: '16px auto' }}>
            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Devices
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.device_platforms?.length > 0 && entireMetaObj.adSetModel.targeting.device_platforms.map((d: string) => d).join(', ')}
                {!entireMetaObj.adSetModel?.targeting?.device_platforms || entireMetaObj.adSetModel?.targeting?.device_platforms?.length < 1 && "desktop, mobile"}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Platforms
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.publisher_platforms?.length > 0 && entireMetaObj.adSetModel?.targeting?.publisher_platforms.map((p: string) => p).join(', ')}
                {!entireMetaObj?.adSetModel?.targeting?.publisher_platforms || entireMetaObj?.adSetModel?.targeting?.publisher_platforms?.length < 1  && "-"}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Facebook positions
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.facebook_positions?.length > 0 && entireMetaObj.adSetModel.targeting.facebook_positions.map((p: string) => p).join(', ')}
                {!entireMetaObj?.adSetModel?.targeting?.facebook_positions || entireMetaObj?.adSetModel?.targeting?.facebook_positions?.length < 1 && "-"}
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{}}>
            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Instagram positions
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.instagram_positions?.length > 0 && entireMetaObj.adSetModel?.targeting?.instagram_positions.map((p: string) => p).join(', ')}
                {!entireMetaObj?.adSetModel?.targeting?.instagram_positions || entireMetaObj?.adSetModel?.targeting?.instagram_positions?.length < 1 && "-"}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Messenger positions
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.messenger_positions?.length > 0 && entireMetaObj.adSetModel.targeting.messenger_positions.map((p: string) => p).join(', ')}
                {!entireMetaObj?.adSetModel?.targeting?.messenger_positions || entireMetaObj?.adSetModel?.targeting?.messenger_positions?.length < 1 && "-"}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography style={styles.body2} variant='body2' sx={{}}>
                Audience network positions
              </Typography>
              <Typography style={styles.caption} variant='caption'>
                {entireMetaObj?.adSetModel?.targeting?.audience_network_positions?.length > 0 && entireMetaObj.adSetModel.targeting.audience_network_positions.map((p: string) => p).join(', ')}
                {!entireMetaObj?.adSetModel?.targeting?.audience_network_positions || entireMetaObj?.adSetModel?.targeting?.audience_network_positions.length < 1  && "-"}
              </Typography>
            </Grid>
          </Grid>
        
        </Grid>
        <Divider sx={{ marginTop: '16px' }} />
      </AccordionDetails>


      {/* Keywords */}
      {
        metaObjType === MetaObjType.Ad_Automation && <AccordionDetails>
          <Typography style={styles.body1} variant='body1' sx={{}}>
            Keywords <IconButton onClick={() => setEditKeywords(!editKeywords)}><Edit sx={{...svg16Styles}} /></IconButton>
          </Typography>
          {editKeywords && <Typography style={styles.body2} variant='body2' sx={{}}>
            <TextField fullWidth variant="standard" label="Keywords (separated by comma)" id="radius-input"
              onChange={handleKeywordsChange} value={entireMetaObj?.keywords} />
          </Typography>}
          {!editKeywords && <Typography style={styles.caption} variant='caption' sx={{}}>
            {entireMetaObj?.keywords && arrayOfStringsToString(entireMetaObj.keywords)}
          </Typography>}
        </AccordionDetails>
      }

      {/* Ads amount */}
      {
        metaObjType === MetaObjType.POST_BOOSTING_RULE && <AccordionDetails>
          <Divider sx={{ marginTop: '16px' }} />
          <Typography style={styles.body1} variant='body1' sx={{}}>
            Ads created within the ad set
          </Typography>
          <Typography style={styles.caption} variant='caption' sx={{}}>
            {entireMetaObj?.createdAds?.length}
          </Typography>
        </AccordionDetails>
      }

      <AccordionActions>
        <Button variant='outlined' sx={{ textTransform: 'none' }} autoFocus color="inherit" onClick={() => setExpanded(!expanded)}>
          Close
        </Button>
      </AccordionActions>
      {
        customerId && entireMetaObj.adSetModel?.targeting && <AdSetTargetingDialog dialogOpen={dialogOpen}
          customerId={customerId}
          adSet={entireMetaObj.adSetModel}
          onCloseDialog={(update, adSetModel, audienceInfo) => {
            setDialogOpen(false)
            if (update && adSetModel && audienceInfo) {
              updateAdAutomationTargeting(customerId, entireMetaObj.id, audienceInfo, adSetModel.targeting).then((_res) => {
                  setEntireMetaObj({ ...entireMetaObj, adSetModel })
                  setExpanded(!expanded)
                  alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Ad set targeting updated successfully!", duration: 7000 }))
                  setTimeout(() => {setExpanded(true)}, 3000);
              }).catch((err) => {
                alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: err.response?.data?.message || "Something went wrong! Unable to update ad set targeting.", duration: null }))
              })
            }
          }}
        />
      }
      {
        /* Optimization Goal Dialog */
        customerId && entireMetaObj.adSetModel?.targeting && <AdSetOptimizationGoalEditDialog dialogOpen={optimizationGoalDialogOpen}
          customerId={customerId}
          entireMetaObj={entireMetaObj}
          onCloseDialog={(update, data) => {
            setOptimizationGoalDialogOpen(false)
            if (update && data) {
              updateAdAutomationOptimizationGoal(customerId, entireMetaObj.id, data).then((res) => {
                  setEntireMetaObj({ ...entireMetaObj, adSetModel: { ...entireMetaObj.adSetModel, ...data } })
                  setExpanded(!expanded)
                  alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Ad set optimization goal updated successfully!", duration: 7000 }))
                  setTimeout(() => {setExpanded(true)}, 3000);
              }).catch((err) => {
                alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: err.response?.data?.message || "Something went wrong! Unable to update ad set optimization goal.", duration: null }))
              })
            }
          }}
        />
      }
    </Accordion >
  )
}

const styles = {
  body1: {
    margin: '10px auto',
    color: '#000'
  },
  body2: {
    margin: '10px auto'
  },
  caption: {
    color: '#7d7d7d'
  },
}

export default SingleMetaAccordion;