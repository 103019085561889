// Campaign objectives
// https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group/#Creating
export enum CampaignObjectives {
  APP_PROMOTION = "OUTCOME_APP_PROMOTION",
  AWARENESS = "OUTCOME_AWARENESS",
  ENGAGEMENT = "OUTCOME_ENGAGEMENT",
  LEADS = "OUTCOME_LEADS",
  SALES = "OUTCOME_SALES",
  TRAFFIC = "OUTCOME_TRAFFIC"
}

// Campaign, ad set or ad status
export enum MarketingObjStatus {
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED"
}

// The restrictions between the campaign objective and the ad set optimization goal
// https://developers.facebook.com/docs/marketing-api/reference/ad-campaign/#restrictions


// Ad set optimization goals when the campaign objective is Engagement
export enum EngagementOptimizationGoals {
  THRUPLAY = "THRUPLAY", // video
  TWO_SECOND_CONTINUOUS_VIDEO_VIEWS = "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS", // video
  POST_ENGAGEMENT = "POST_ENGAGEMENT", // post, event
  IMPRESSIONS = "IMPRESSIONS", // post, event, website
  REACH = "REACH", // post, event, website, app
  EVENT_RESPONSES = "EVENT_RESPONSES", // event
  CONVERSATIONS = "CONVERSATIONS", // Messenger, WhatsApp, Instagram
  LINK_CLICKS = "LINK_CLICKS", // Messenger, WhatsApp, Instagram, website, app
  OFFSITE_CONVERSIONS = "OFFSITE_CONVERSIONS", // website
  LANDING_PAGE_VIEWS = "LANDING_PAGE_VIEWS", // website
  APP_INSTALLS_AND_OFFSITE_CONVERSIONS = "APP_INSTALLS_AND_OFFSITE_CONVERSIONS", // app
  PAGE_LIKES = "PAGE_LIKES", // on Page
}

export enum EngagementConversions {
  MESSAGING_APPS = "MESSAGING_APPS",
  ON_YOUR_AD = "ON_YOUR_AD",
  WEBSITE = "WEBSITE",
  // APP = "APP",
  FACEBOOK_PAGE = "FACEBOOK_PAGE"
}

export enum MessagingAppsConversionOptimizationGoals {
  CONVERSATIONS = "CONVERSATIONS"
}

export enum OnYourAdConversionOptimizationGoals {
  THRUPLAY = "THRUPLAY", // video
  TWO_SECOND_CONTINUOUS_VIDEO_VIEWS = "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS", // video
}

export enum EngagementWebsiteConversionOptimizationGoals {
  OFFSITE_CONVERSIONS = "OFFSITE_CONVERSIONS", // website
  LANDING_PAGE_VIEWS = "LANDING_PAGE_VIEWS", // website
  LINK_CLICKS = "LINK_CLICKS", // Messenger, WhatsApp, Instagram, website, app
  IMPRESSIONS = "IMPRESSIONS", // post, event, website
  REACH = "REACH", // post, event, website, app
  POST_ENGAGEMENT = "POST_ENGAGEMENT",
  PAGE_LIKES = "PAGE_LIKES"
}

export enum TrafficWebsiteConversionOptimizationGoals {
  LANDING_PAGE_VIEWS = "LANDING_PAGE_VIEWS", // website
  LINK_CLICKS = "LINK_CLICKS", // Messenger, WhatsApp, Instagram, website, app
  IMPRESSIONS = "IMPRESSIONS", // post, event, website
  REACH = "REACH", // post, event, website, app
}

export enum FacebookPageConversionOptimizationGoals {
  IMPRESSIONS = "IMPRESSIONS", // post, event, website
  PAGE_LIKES = "PAGE_LIKES", // on Page
}

// Ad set optimization goals when the campaign objective is Traffic
export enum TrafficOptimizationGoals {
  LANDING_PAGE_VIEWS = "LANDING_PAGE_VIEWS", // website
  LINK_CLICKS = "LINK_CLICKS", // website, app, Messenger, WhatsApp
  IMPRESSIONS = "IMPRESSIONS", // website, Messenger, WhatsApp
  REACH = "REACH", // website, app, Messenger, WhatsApp
}

export enum TrafficConversions {
  WEBSITE = "WEBSITE",
  MESSENGER = "MESSENGER",
  WHATSAPP = "WHATSAPP",
}

export enum MessengerAndWhatsAppConversionsOptimizationGoals {
  LINK_CLICKS = "LINK_CLICKS", // website, app, Messenger, WhatsApp
  REACH = "REACH", // website, app, Messenger, WhatsApp
  IMPRESSIONS = "IMPRESSIONS", // website, Messenger, WhatsApp
}

// Ad set optimization goals when the campaign objective is Awareness
export enum AwarenessOptimizationGoals {
  AD_RECALL_LIFT = "AD_RECALL_LIFT",
  IMPRESSIONS = "IMPRESSIONS",
  REACH = "REACH",
}

// Ad set optimization goals when the campaign objective is Lead
export enum LeadsOptimizationGoals {
  OFFSITE_CONVERSIONS = "OFFSITE_CONVERSIONS", // website
  ONSITE_CONVERSIONS = "ONSITE_CONVERSIONS", // website
  LANDING_PAGE_VIEWS = "LANDING_PAGE_VIEWS", // website
  LINK_CLICKS = "LINK_CLICKS", // website, app
  IMPRESSIONS = "IMPRESSIONS", // website
  REACH = "REACH", // website, app
  LEAD_GENERATION = "LEAD_GENERATION", // instant forms, Messenger
  QUALITY_LEAD = "QUALITY_LEAD", // instant forms, Messenger
  QUALITY_CALL = "QUALITY_CALL", // Calls
  APP_INSTALLS_AND_OFFSITE_CONVERSIONS = "APP_INSTALLS_AND_OFFSITE_CONVERSIONS" // app
}

export enum LeadsConversions {
  WEBSITE = "WEBSITE",
  INSTANT_FORMS = "INSTANT_FORMS",
  MESSENGER = "MESSENGER",
  INSTANT_FORMS_AND_MESSENGER = "INSTANT_FORMS_AND_MESSENGER",
  CALLS = "CALLS",
  APP = "APP"
}

// Ad set billing event
export enum AdSetBillingEvent {
  IMPRESSIONS = "IMPRESSIONS",
  LINK_CLICKS = "LINK_CLICKS",
  PAGE_LIKES = "PAGE_LIKES",
  THRUPLAY = "THRUPLAY", // video
  TWO_SECOND_CONTINUOUS_VIDEO_VIEWS = "TWO_SECOND_CONTINUOUS_VIDEO_VIEWS" // video
}


export enum BudgetType {
  daily = "daily",
  lifetime = "lifetime",
}

// ***** Placement enums start *****
// info Placement targeting: https://developers.facebook.com/docs/marketing-api/audiences/reference/placement-targeting/#newplacement

export enum DevicePlatforms {
  mobile = "mobile",
  desktop = "desktop",
  all = 'all'
}

export enum PublisherPlatforms {
  facebook = "facebook",
  instagram = "instagram",
  messenger = "messsenger",
  audience_network = "audience_network",
  all = 'all'
}

export enum FacebookPositions {
  feed = "feed",
  right_hand_column = "right_hand_column",
  instant_article = "instant_article",
  marketplace = "marketplace",
  video_feeds = "video_feeds",
  story = "story",
  search = "search",
  instream_video = "instream_video",
  facebook_reels = "facebook_reels",
  all = 'all'
}

export enum InstagramPositions {
  stream = "stream",
  story = "story",
  shop = "shop",
  explore = "explore",
  reels = "reels",
  all = 'all'
}

export enum AudienceNetworkPositions {
  classic = "classic",
  rewarded_video = "rewarded_video",
  all = 'all'
}

export enum MessengerPositions {
  messenger_home = "messenger_home",
  sponsored_messages = "sponsored_messages",
  story = "story",
  all = 'all'
}

// ***** Placement enums end *****


export enum AdPromotedObjectCustomEventType {
  RATE = "RATE",
  CONTACT = "CONTACT",
  FIND_LOCATION = "FIND_LOCATION",
  LEAD = "LEAD",
  CONTENT_VIEW = "CONTENT_VIEW",
  OTHER = "OTHER",
  SEARCH = "SEARCH",
  SERVICE_BOOKING_REQUEST = "SERVICE_BOOKING_REQUEST"
}

// https://developers.facebook.com/docs/marketing-api/reference/ad-promoted-object/
export type AdPromotedObject = {
  // page_id:any, // The ID of a Facebook Page
  pixel_id: any, // The ID of a Facebook conversion pixel. Used with offsite conversion campaigns.
  custom_event_type: AdPromotedObjectCustomEventType, // If you use pixel_id, you must provide custom_event_type
  // pixel_rule: any, // A JSON rule that will decide whether an action from a pixel matches this promoted object spec
}

export type SavedAudience = {
  id: string,
  name: string,
  targeting: any,
  account: any,
  approximate_count_lower_bound: number,
  approximate_count_upper_bound: number,
  run_status: string,
  sentence_lines: any[]
}


export type AdSetTargetCity = {
  name: string,
  key: string,
  radius: number,
  distance_unit: string
}

export type AdSetAudienceInfo = {
  updateAllAdSetsWithNewTargeting: boolean,
  savedAudience: boolean,
  savedAudienceId: string,
  savedAudienceName: string
}

export type AdSetTargeting = {
  age_min: number;
  age_max: number;
  genders: number[];
  geo_locations: {
    countries: string[];
    cities?: AdSetTargetCity[];
    zips?: string[];
  };
  flexible_spec?: any[],
  locales?: number[] | string[],
  device_platforms?: DevicePlatforms[];
  publisher_platforms?: PublisherPlatforms[];
  facebook_positions?: FacebookPositions[];
  audience_network_positions?: AudienceNetworkPositions[];
  instagram_positions?: InstagramPositions[];
  messenger_positions?: MessengerPositions[];
  // helper field. Does not belong to the ad set targeting fields defined by Meta.
  placementsSelection: string
};

export type AdSetTargetingOmittedManualAudience = Omit<AdSetTargeting, 'age_min' | 'age_max' | 'genders' | 'geo_locations'>

export enum BidStrategy {
  LOWEST_COST_WITHOUT_CAP = "LOWEST_COST_WITHOUT_CAP", // automatic bidding. No need for bid_amount field
  LOWEST_COST_WITH_BID_CAP = "LOWEST_COST_WITH_BID_CAP", // manual maximum-cost bidding. bid_amount field is required
  COST_CAP = "COST_CAP"
}

export enum BudgetLevel {
  CAMPAIGN_LEVEL = 'campaign_level',
  AD_SET_LEVEL = 'ad_set_level'
}

export type BudgetProps = {
  budgetLevel: BudgetLevel,
  monthlyBudget?: number;
  budget_type: BudgetType,
  daily_budget: number,
  lifetime_budget: number,
  budget: number,
  bid_strategy: BidStrategy,
  bid_amount: number
}

export type Campaign = {
  id?: string;
  name: string;
  objective: CampaignObjectives;
  status: MarketingObjStatus;
  special_ad_categories: string[];
  // budget fields - start
  budget_type?: BudgetType;
  budget?: number;
  daily_budget?: number;
  lifetime_budget?: number;
  bid_strategy?: BidStrategy;
  // budget fields - end
};

export type AdSet = {
  id?: string;
  name: string;
  campaign_id: string;
  // budget fields - start
  budget_type?: BudgetType;
  budget?: number;
  daily_budget?: number;
  lifetime_budget?: number;
  bid_strategy?: BidStrategy;
  bid_amount?: number;
  // budget fields - end
  status: MarketingObjStatus;
  start_time: string;
  end_time: string;
  conversion: EngagementConversions | TrafficConversions | LeadsConversions
  optimization_goal: EngagementOptimizationGoals | TrafficOptimizationGoals | AwarenessOptimizationGoals | LeadsOptimizationGoals | any;
  billing_event: AdSetBillingEvent;
  promoted_object: AdPromotedObject; // When you create ad sets for campaigns with the certain objectives, promoted_object is required
  targeting: AdSetTargeting;
  // helper fields. Do not belong to the ad set fields defined by Meta.
  audience: string
  currentSavedAudienceId: string
  currentSavedAudience: SavedAudience | undefined
  dynamicPeriodOfTime?: boolean;
  dynamicDays?: number;
  defaultCityRadius?: number;
};

// Type of call to action button in your ad.
// This determines the button text and header text for your ad.
// Note: not all enum properties are included.
// Info: https://developers.facebook.com/docs/marketing-api/reference/ad-creative#fields
export enum CallToActionButtonType {
  OPEN_LINK = 'OPEN_LINK',
  LIKE_PAGE = 'LIKE_PAGE',
  CALL = 'CALL',
  WATCH_VIDEO = 'WATCH_VIDEO',
  LEARN_MORE = 'LEARN_MORE',
  DOWNLOAD = 'DOWNLOAD',
  NO_BUTTON = 'NO_BUTTON',
  VISIT_PAGES_FEED = 'VISIT_PAGES_FEED',
  CALL_NOW = 'CALL_NOW',
  APPLY_NOW = 'APPLY_NOW',
  CONTACT = 'CONTACT',
  GET_OFFER = 'GET_OFFER',
  GET_QUOTE = 'GET_QUOTE',
  GET_SHOWTIMES = 'GET_SHOWTIMES',
  SHARE = 'SHARE',
  CONTACT_US = 'CONTACT_US',
  SEE_MORE = 'SEE_MORE',
  FOLLOW_PAGE = 'FOLLOW_PAGE',
  LISTEN_NOW = 'LISTEN_NOW',
  REQUEST_TIME = 'REQUEST_TIME',
}

export type Ad = {
  urlParameters: string;
  addCTA: boolean;
  callToActionButtonType: CallToActionButtonType,
  url: string
};

export type AdAutomationSettings = {
  name: string;
  active: boolean;
  budgetProps: BudgetProps;
  campaign: Campaign;
  adSet: AdSet;
  ad: {
    urlParameters: string;
  }
  keywords: string[];
  maxNumberOfActiveAds: number;
};

export type DBAdAutomation = {
  id: string;
  name: string;
  active: boolean;
  campaign: string;
  adSet: string;
  keywords: string[];
  createdAt: string;
};

export type EntireAdAutomation = {
  id: string;
  name: string;
  active: boolean;
  campaign: Campaign;
  adSet: AdSet;
  keywords: string[];
  ads: string[];
  adCreatives: [];
  createdAt: string;
};


export type PostBoostingCondition = {
  id?: string | number,
  conditionText: string,
  operator: string,
  textOperator: string,
  value: string | number
};

export type PostBoostingFilters = {
  types: string[],
  conditions: PostBoostingCondition[],
  logicalOperator: string
}

export type AdvancedPostBoosting = {
  name: string,
  filters: PostBoostingFilters,
  newOrExistingCampaign: string,
  budgetProps: BudgetProps,
  campaign: Campaign | any
  adSet: AdSet
  ad: Ad
}

export type MarketingSettings = {
  currentConversionLocations: EngagementConversions[] | TrafficConversions[],
  currentOptimizationGoals: AwarenessOptimizationGoals[] | TrafficOptimizationGoals[] | EngagementOptimizationGoals[],
  currentBillingEvents: AdSetBillingEvent[]
}

export const GET_AD_AUTOMATION_SETTINGS = "GET_AD_AUTOMATION_SETTINGS";
export const SET_AD_AUTOMATION_SETTINGS = "SET_AD_AUTOMATION_SETTINGS";
export const SET_AD_AUTOMATIONS = "SET_AD_AUTOMATIONS";
export const SET_AD_AUTOMATION_SETTINGS_ERRORS = "SET_AD_AUTOMATION_SETTINGS_ERRORS";
export const GET_ALL_POSTS = 'SET_ALL_POSTS';
export const SET_ALL_POSTS = 'SET_ALL_POSTS';
export const SET_MATCHED_POSTS = 'SET_MATCHED_POSTS';
export const GET_MATCHED_POSTS = 'GET_MATCHED_POSTS';
export const SET_ADVANCED_POST_BOOSTING = 'SET_ADVANCED_POST_BOOSTING'
export const SET_ADVANCED_POST_BOOSTING_ERRORS = "SET_ADVANCED_POST_BOOSTING_ERRORS"
export const SET_POST_BOOSTING_RULES = 'SET_POST_BOOSTING_RULES'
export const SET_CAMPAIGN = 'SET_CAMPAIGN'
export const SET_AD_SET = 'SET_AD_SET'
export const SET_MARKETING_SETTINGS = 'SET_MARKETING_SETTINGS'
export const SET_CAMPAIGN_ERRORS = "SET_CAMPAIGN_ERRORS";
export const SET_AD_SET_ERRORS = 'SET_AD_SET_ERRORS';
export const SET_POSTS_START_DATE = 'SET_POSTS_START_DATE';
export const SET_POSTS_END_DATE = 'SET_POSTS_END_DATE';
export const SET_META_ASSETS = 'SET_META_ASSETS'

type GetAdAutomationSettingsAction = {
  type: typeof GET_AD_AUTOMATION_SETTINGS;
};

type SetAdAutomationSettingsAction = {
  type: typeof SET_AD_AUTOMATION_SETTINGS;
  data: AdAutomationSettings;
};

type SetAdAutomationsAction = {
  type: typeof SET_AD_AUTOMATIONS;
  data: any[];
};

type SetAdAutomationSettingsErrorsAction = {
  type: typeof SET_AD_AUTOMATION_SETTINGS_ERRORS;
  data: string[];
};


type SetAdvancedPostBoostingErrorsAction = {
  type: typeof SET_ADVANCED_POST_BOOSTING_ERRORS;
  data: string[];
};

type GetAllPosts = {
  type: typeof GET_ALL_POSTS;
  data: any[]
};

type SetAllPosts = {
  type: typeof SET_ALL_POSTS;
  data: any[]
};

type GetMatchedPosts = {
  type: typeof GET_MATCHED_POSTS;
  data: any[]
};

type SetMatchedPosts = {
  type: typeof SET_MATCHED_POSTS;
  data: any[]
};

type SetAdvancedPostBoosting = {
  type: typeof SET_ADVANCED_POST_BOOSTING;
  data: AdvancedPostBoosting
};

type SetPostBoostingRules = {
  type: typeof SET_POST_BOOSTING_RULES;
  data: any[]
};

type SetCampaign = {
  type: typeof SET_CAMPAIGN;
  data: Campaign
};

type SetAdSet = {
  type: typeof SET_AD_SET;
  data: AdSet
};

type SetMaektingSettings = {
  type: typeof SET_MARKETING_SETTINGS;
  data: any
};

type SetCampaignErrors = {
  type: typeof SET_CAMPAIGN_ERRORS;
  data: string[];
}

type SetAdSetErrors = {
  type: typeof SET_AD_SET_ERRORS;
  data: string[];
}


type SetPostsStartDate = {
  type: typeof SET_POSTS_START_DATE;
  data: string;
}

type SetPostsEndDate = {
  type: typeof SET_POSTS_END_DATE;
  data: string;
}

type SetMetaAssets = {
  type: typeof SET_META_ASSETS;
  data: any;
}

export type MetaAssets = {
  userAdAccounts: any[],
  userFacebookPages: any[]
  currentAssets: {
    adAccountId: string,
    adAccountName: string,
    facebookPageId: string,
    facebookPageName: string
  },
  savedAssetsIntoDB: {
    adAccountId: string,
    adAccountName: string,
    facebookPageId: string,
    facebookPageName: string
  }
  openMetaAssetsDialog: boolean
}

export type MetaAction = GetAdAutomationSettingsAction | SetAdAutomationSettingsAction | SetAdAutomationSettingsErrorsAction | SetAdAutomationsAction | SetAllPosts | GetMatchedPosts | SetMatchedPosts | GetAllPosts | SetAdvancedPostBoosting | SetPostBoostingRules | SetAdvancedPostBoostingErrorsAction | SetCampaign | SetAdSet | SetMaektingSettings | SetCampaignErrors | SetAdSetErrors | SetPostsStartDate | SetPostsEndDate | SetMetaAssets

export type MetaState = {
  campaign: Campaign;
  campaignErrors: string[];
  adSet: AdSet;
  adSetErrors: string[];
  marketingSettings: MarketingSettings;
  adAutomationSettings: AdAutomationSettings;
  adAutomationSettingsErrors: string[];
  adAutomations: any[];
  allPosts: any[];
  matchedPosts: any[];
  postsStartDate: string;
  postsEndDate: string;
  advancedPostBoosting: AdvancedPostBoosting,
  advancedPostBoostingErrors: string[];
  postBoostingRules: any[]
  metaAssets: MetaAssets
};

export enum NewOrExsitingCampaing {
  NEW_CAMPAIGN = "new-campaign",
  EXISTING_CAMPAIGN = "existing-campaign"
}

export enum MetaErrors {
  // ad automation
  adAutomationNameError = "Ad automation name should be at least 3 characters",
  // campaign
  campaignNameError = "Campaign name should be at least 3 characters",
  // ad set
  adSetNameError = "Ad set name should be at least 3 characters",

  budgetError = 'Budget should be between 1 and 1000 €',
  bidAmountError = 'Bid amount should be between 0.1 and 100 € (10 - 10000 cents)',
  adSetDaysError = "Number of days should be between 1 and 365.",
  adSetMinAgeError = "Min age should be a number between 13 and 80",
  adSetMaxAgeError = "Max age should be a number between 13 and 80",

  // Advanced post boosting
  advancedPostBoostingRuleNameError = "Rule name should be at least 3 characters"

}

export enum BoostingType {
  ADVANCED = 'advanced',
  BASIC = 'basic'
}

export enum MetaObjType {
  Ad_Automation = 'ad-automation',
  POST_BOOSTING_RULE = 'post-boosting-rule'
}