import React from 'react'
import { CompetitorAction, CompetitorState, CompetitorAdUnits, SET_COMPETITOR_AD_UNITS, MetaCompetitorAdType, SET_COMPETITOR_FACEBOOK_PAGES, SET_GOOGLE_ADVERTISERS, CompetitorSettings, SET_COMPETITOR_SETTINGS } from './CompetitorTypes';
import { CategoryItem } from '../ads/AdsTypes';


export const allCompetitorAdsCategories: CategoryItem[] = [
  { value: "meta", label: "Meta" },
  { value: "YOUTUBE", label: "Youtube" },
  { value: "SEARCH", label: "Google Search" },
  { value: "SHOPPING", label: "Google Shopping" },
  { value: "MAPS", label: "Google Maps" },
  { value: "PLAY", label: "Google Play" }
]

export const allCompetitorAdsCategoriesAsStrings: string[] = allCompetitorAdsCategories.map((c: CategoryItem) => c.value)

export const initialCompetitorSettings: CompetitorSettings = {
  meta: {
    facebookPages: []
  },
  google: {
    advertisers: []
  }
}

export const initialCompetitorAdUnits: CompetitorAdUnits = {
  startDate: new Date(Date.now() - (30 * 24 * 60 * 60 * 1000)),
  endDate: new Date(Date.now() - (1 * 24 * 60 * 60 * 1000)),
  searchKeyword: "",
  status: "ACTIVE",
  creatives: { creatives: [] },
  categories: allCompetitorAdsCategoriesAsStrings,
  facebookPageIds: [],
  googleAdvertiserIds: [],
  adType: MetaCompetitorAdType.ALL,
  loading: false,
  gridViewItemsCount: 3
}

export const initialCompetitorState: CompetitorState = {
  competitorAdUnits: initialCompetitorAdUnits,
  competitorSettings: initialCompetitorSettings
};

const CompetitorReducer = (state: CompetitorState, action: CompetitorAction): CompetitorState => {
  switch (action.type) {
    case SET_COMPETITOR_AD_UNITS:
      return {
        ...state,
        competitorAdUnits: action.data
      };
    case SET_COMPETITOR_SETTINGS:
      return {
        ...state,
        competitorSettings: action.data
      };
    case SET_COMPETITOR_FACEBOOK_PAGES:
      return {
        ...state,
        competitorSettings: {
          ...state.competitorSettings,
          meta: {
            ...state.competitorSettings.meta,
            facebookPages: action.data
          }
        }
      };
    case SET_GOOGLE_ADVERTISERS:
      return {
        ...state,
        competitorSettings: {
          ...state.competitorSettings,
          google: {
            ...state.competitorSettings.google,
            advertisers: action.data
          }
        }
      };
    default:
      return state;
  }
};

export default CompetitorReducer