

import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { ReadpeakAdsSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { apiSaveReadpeakCredentials } from '../../api/Auth';
import { apiGetCompetitorSettings, apiGetReadpeakSettings } from '../../api/Customer';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setAlert } from '../../context/alert/AlertAction';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import * as uuid from 'uuid'
import { regularBtnStyles } from '../../styles/buttons';
import { MetaCompetitorSettings } from '../Competitors/MetaCompetitorSettings';
import { AdminAccordion } from '../AdminAccordion';
import { GoogleCompetitorSettings } from '../Competitors/GoogleCompetitorSettings';
import { useCompetitorStateValue } from '../../context/competitors/CompetitorContext';
import { CompetitorSettings } from '../../context/competitors/CompetitorTypes';
import { setCompetitorSettings } from '../../context/competitors/CompetitorActions';

const textFieldTextStyles = {
  mb: 0
}

const gridItemStyles = {
  mt: 0,
  mb: 0
}

export type CompetitorsAdminProps = {
  customerId: number
}

export const CompetitorsAdmin = (props: CompetitorsAdminProps) => {
  const { customerId } = props
  const [saveBtnProgress, setSaveBtnProgress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [{ competitorSettings }, competitorDispatch] = useCompetitorStateValue()

  const [settings, setSettings] = useState<CompetitorSettings>(competitorSettings)
  const [{ alert }, alertDispatch] = useAlertStateValue()

  useEffect(() => {
    apiGetCompetitorSettings(customerId).then((response: CompetitorSettings) => {
      console.log("res", response)
      if (response) {
        setSettings(response)
        competitorDispatch(setCompetitorSettings(response))
      } else {
        setSettings(settings)
      }
    })
  }, [customerId])

  return (
  <Grid container>
    {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
    <AdminAccordion title='Google'>
        <GoogleCompetitorSettings customerId={customerId}/>
      </AdminAccordion>
      <AdminAccordion title='Meta'>
        <MetaCompetitorSettings customerId={customerId}/>
      </AdminAccordion>
    </Grid>}
  </Grid>
  )
}