// https://mui.com/material-ui/customization/palette/
// https://mui.com/material-ui/customization/color/
// dark white '#FAFAFA'
import { goldColors, neutralColors, primaryColors } from "./colors"

export const blackBtn = {
  backgroundColor: '#333',
  borderColor: '#333',
  color: '#fff',
  borderRadius: '25px',
  '&: hover': {
    backgroundColor: '#000',
    borderColor: '#000',
  }
}

export const whiteBtnBlackBorder = {
  backgroundColor: '#fff',
  borderRadius: '25px',
  color: '#000',
  border: '1px solid black',
  '&: hover': {
    backgroundColor: 'rgb(226, 226, 226, 0.5)',
    border: '1px solid black'
  },
  '&: disabled': {
    border: '1px solid lightgray'
  }
}

export const redBtn = {
  padding: '10px 23px',
  fontSize: '14px',
  lineHeight: '100%',
  backgroundColor: '#FC8C8C',
  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
  borderColor: '#FC8C8C',
  color: '#000',
  borderRadius: '5px',
  '&: hover': {
    backgroundColor: '#FC8C8C',
    borderColor: '#FC8C8C'
  }
}

export const regularBtnStyles = {
  padding: '10px 23px',
  borderRadius: '5px',
  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '14px',
  lineHeight: '100%',
  color: primaryColors[950],
  background: primaryColors[100],
  border: `0.5 solid ${primaryColors[100]}`,
  '&:hover': {
    border: `0.5 solid ${primaryColors[300]}`,
    background: primaryColors[300]
  },
  '&.Mui-disabled': {
    color: neutralColors[400],
    borderColor: neutralColors[300]
  }
}

export const whiteBlackBorderBtnStyles = {
  padding: '10px 23px',
  borderRadius: '5px',
  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '14px',
  lineHeight: '100%',
  color: neutralColors[950],
  background: '#fff',
  border: `0.5px solid ${neutralColors[950]}`,
  '&:hover': {
    border: `0.5px solid ${neutralColors[300]}`,
    background: neutralColors[300]
  },
  '&.Mui-disabled': {
    color: neutralColors[400],
    borderColor: neutralColors[300]
  }
}

export const svg16Styles = {
  width: '16px',
  height: '16px'
}

export const svgStyles = (w: string, h: string) => {
  return { 
    width: w, 
    height: h 
  }
}


export const goldBtnStyles = {
  padding: '10px 23px',
  borderRadius: '5px',
  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '14px',
  lineHeight: '100%',
  color: '#FFFFFF',
  background: goldColors.primary,
  border: `0.5 solid ${goldColors.primary}`,
  '&:hover': {
    border: `0.5 solid ${goldColors.hover}`,
    background: goldColors.hover
  },
  '&.Mui-disabled': {
    color: neutralColors[400],
    borderColor: neutralColors[300]
  }
}