import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { primaryColors } from '../../styles/colors';
import { beautifyString } from '../../utils/helpers';
import IosShareIcon from '@mui/icons-material/IosShare';


interface Data {
  id: number,
  keyword: string;
  monthlySearchVolumes: any[]
}

function createData(
  id: number,
  keyword: string,
  monthlySearchVolumes: any[]
): Data {
  return {
    id,
    keyword,
    monthlySearchVolumes
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(order: Order,orderBy: Key,): (
  a: { [key in Key]: number | string | any[] },
  b: { [key in Key]: number | string | any[] },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface HeadCellBase {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const baseHeadCells: readonly HeadCellBase[] = [
  {
    id: 'keyword',
    numeric: false,
    disablePadding: true,
    label: 'Keyword',
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  yearMonths: string[]
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, yearMonths } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const yearMonthCells = yearMonths.map(i => ({
    id: i,
    numeric: true,
    disablePadding: false,
    label: `${i.split("-")[1].substring(0, 3)} ${i.split("-")[0]}`,
  }))

  const headCells: readonly any[]  = [...baseHeadCells, ...yearMonthCells]

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
interface EnhancedTableToolbarProps {
  numSelected: number;
  handleExportCSV: () => void
}
function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected, handleExportCSV } = props;
  return (
    <Toolbar
      sx={[
        {
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        },
        numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        },
      ]}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Keywords
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Export (CSV)">
          <IconButton onClick={handleExportCSV}>
            <IosShareIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

interface KeywordPlannerTableProps {
  yearMonths: string[]
  keywordPlan: any[],
  loading: boolean
}
export default function KeywordPlannerTable({keywordPlan, loading, yearMonths}: KeywordPlannerTableProps) {

  const [rows, setRows] = React.useState<Data[]>([])
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('keyword');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    setRows(
      keywordPlan?.map((i, index) =>
        createData(
          index,
          i.keyword,
          i.monthlySearchVolumes
        )
      )
    );
  }, [keywordPlan, loading]);
  
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      [...rows]
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [order, orderBy, page, rowsPerPage],
  );

  const handleExportCSV = () => {
    const selectedRows = rows.filter((row) => selected.includes(row.id));
    
    const totalMonthlySearches:  { [id: string]: number }  = {};
    yearMonths.forEach((ym) => {
      totalMonthlySearches[ym] = selectedRows.reduce((sum, row) => sum + row.monthlySearchVolumes.find((vol) => vol.yearMonth === ym)?.monthlySearches || 0, 0);
    });
  
    const csvContent = [];
  
    const headers = ['ID', 'Keyword', ...yearMonths.map((ym) => `${ym.split('-')[1]} ${ym.split('-')[0]}`)];
    csvContent.push(headers.join(','));
  
    selectedRows.forEach((row) => {
      const rowContent = [row.id, row.keyword];
      row.monthlySearchVolumes.forEach((vol: any) => {
        const shareOfSearch = ((vol.monthlySearches / totalMonthlySearches[vol.yearMonth]) * 100).toFixed(2);
        rowContent.push(`${vol.monthlySearches} (${shareOfSearch}%)`);
      });
      csvContent.push(rowContent.join(','));
    });
  
    const totalRow = ['Total', rows.length - 1, ...yearMonths.map((ym) => totalMonthlySearches[ym])];
    csvContent.push(totalRow.join(','));
  
    const csvString = csvContent.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
  
    const link = document.createElement('a');
    link.href = url;
    link.download = 'exported_data.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  
  return (
    <Box sx={{ width: '100%' }}>
      {loading && <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', padding: '36px'}}><CircularProgress style={{ width: '32px', height: '32px', color: primaryColors[300], margin: 'auto' }} />
      </Box>}
      {!loading && <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} handleExportCSV={handleExportCSV} />
        <TableContainer>
        <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}  // dense ? 'small' : 'medium'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              yearMonths={yearMonths}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = selected.includes(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {beautifyString(row.keyword)}
                    </TableCell>
                    {row?.monthlySearchVolumes?.map((i, index) =>
                      (<TableCell key={index} align="right">
                        {i.monthlySearches}{" "} 
                        (<b>{i.share}</b>)
                      </TableCell>)
                    )}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell>
                  Total 
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                >
                  {rows.length}
                </TableCell>
                {rows[0]?.monthlySearchVolumes?.map((i, index) =>
                      (<TableCell key={index} align="right">
                        {i.totalMonthlySearches} 
                      </TableCell>)
                    )}
              </TableRow>
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows, // dense ? 33 : 53
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          </Box>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>}
    </Box>
  );
}