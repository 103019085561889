import styled from 'styled-components';

const Image = styled.img`
    width: 124px;
    align-self: center;
`


export const KivaLogo = () => <Image src="/kiva-logo.png" />

export const KivaSuiteLogo = (props: any) => <img src="/kiva-suite-logo-hr.png" style={{ alignSelf: 'center', width: props.width }} />

export const KivaCssLogo = (props: any) => <img src="/kiva-css.png" style={{ alignSelf: 'center', width: props.width }} />

