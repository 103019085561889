import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { closeIcon } from '../../utils/icons';
import { neutralColors } from '../../styles/colors';
import { desktop_h2, desktop_p2 } from '../../styles/textStyles';
import { StyledMenu } from '../Ads/StyledMenu';
import FilterField from '../Ads/FilterField';
import AdsSearchBtn from '../Ads/AdsSearchBtn';
import { useCompetitorStateValue } from '../../context/competitors/CompetitorContext';
import { useCustomerState } from '../../context/customer/CustomerContext';
import { setCompetitorAdUnits } from '../../context/competitors/CompetitorActions';
import { apiGetMetaSettings } from '../../api/Customer';
import { IdNameObj } from '../../api/Types';

export type CampaignsProps = {
  handleAdsSearchBtnClick: () => void
  facebookPages?: IdNameObj[]
}

const CompetitorFacebookPagesFilter = ({ handleAdsSearchBtnClick }: CampaignsProps) => {

  const customerState = useCustomerState()
  const { selected } = customerState
  const [{ competitorAdUnits, competitorSettings }, competitorDispatch] = useCompetitorStateValue()
  const { facebookPages } = competitorSettings.meta
  const { facebookPageIds } = competitorAdUnits

  const [selectAll, setSelectAll] = useState<boolean>(facebookPages.length > 0 && facebookPages.length === facebookPageIds.length)

  useEffect(() => {
    if(facebookPages.length > 0 && facebookPages.length === facebookPageIds.length){
      setSelectAll(true)
      selectAllPages()
      handleAdsSearchBtnClick()
    }
  }, [facebookPages, facebookPageIds.length])

  const selectSinglePage = (value: string) => {
    competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, facebookPageIds: [value] }))

  }
  const selectAllPages = () => {
    competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, facebookPageIds: facebookPages?.map(p => (p.id))}))
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    handleAdsSearchBtnClick();
  };

  const [filter, setFilter] = useState('')

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value)
  }

  const handleSelectAllPages = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    if (checked) {
      selectAllPages()
    } else {
      competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, facebookPageIds: [] }))
    }
    setSelectAll(checked)
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const i = event.target.value;
    let updatedPageIds = null;

    if (checked && (facebookPageIds).indexOf(i) === -1) {
      updatedPageIds = [...facebookPageIds, i]
    } else {
      updatedPageIds = facebookPageIds.filter(c => c !== i)
    }
    competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, facebookPageIds: updatedPageIds }))
    setSelectAll(updatedPageIds?.length === facebookPages.length)
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={''}
        sx={{
          textTransform: 'none',
          boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.25)',
          background: "#fff",
          minWidth:'124px',
          color: "#000",
          borderRadius: '5px',
          border: '0.5px solid #000',
          ":hover": { background: '#fff' }
        }}
      >
        Facebook pages {selectAll ? "(all)" : `(${facebookPageIds.length})`}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <Grid container sx={{ padding: '32px', width: '100%', background: neutralColors[50] }}>

          <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}  >
            <Box >
              <Typography variant='h2' sx={desktop_h2}>Facebook Pages</Typography>
            </Box>
            <Box sx={{}}>

              <IconButton onClick={handleClose}>
                {closeIcon()}
              </IconButton>

            </Box>
          </Grid>

          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '36px', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <FilterField filter={filter} handleFilterChange={handleFilterChange} />
            </Box>
            <Box>
              <FormControlLabel
                label={"All"}
                sx={desktop_p2}
                control={<Checkbox checked={selectAll} onChange={handleSelectAllPages}
                  sx={{}}
                />}
              />
            </Box>
            {facebookPages?.filter(i => i.name.toString().toLowerCase().includes(filter?.toLowerCase())).map((i: {id: string, name: string}) => {
              const checked = selectAll ? true : (facebookPageIds).indexOf(i.id) > -1;

              return (<Grid key={`${i.id}`} container sx={{ justifyContent: 'space-between', alignItems: 'center', gap: '16px' }}  >
                <FormControlLabel
                  label={i.name}
                  sx={desktop_p2}
                  control={<Checkbox checked={checked} value={i.id} onChange={handleCheckboxChange}
                    sx={{}}
                  />}
                />

                {facebookPages.length > 1 && <Button onClick={(event) => {
                  event.stopPropagation();
                  if (checked && (facebookPageIds.length === 1)) {
                    selectAllPages()
                    setSelectAll(true)
                  }
                  else {
                    selectSinglePage(i.id)
                    setSelectAll(false)
                  }
                }}
                  sx={desktop_p2}
                >

                  {checked && (facebookPageIds.length === 1) ? "ALL" : "ONLY"} 
                </Button>}
              </Grid>)

            })}
          </Box>

          <Grid container sx={{ justifyContent: 'right', marginTop: '64px' }} >
            <AdsSearchBtn handleAdsSearchBtnClick={handleClose} />
          </Grid>

        </Grid>



      </StyledMenu>
    </div>
  );
}

export default CompetitorFacebookPagesFilter