import moment from "moment"
import { postApi } from "./Utils"

export type GetKeywordPlanParams = {
  keywords: string[],
  startDate: Date,
  endDate: Date
}

export type KeywordPlanResponse = {
  plan: never[],
  yearMonths: string[]
}


export const apiGetKeywordPlan = async (customerId: number, params: GetKeywordPlanParams): Promise<KeywordPlanResponse> => {
  return postApi(`/keyword-planner/customer/${customerId}`, {
    ...params,
    startDate: moment(params.startDate.toISOString()).format("YYYY-MM-DD"),
    endDate: moment(params.endDate.toISOString()).format("YYYY-MM-DD"),
  }, { plan: [], yearMonths: [] })
}
