import React from 'react';
import { loginUserAuth0 } from '../context/auth/AuthActions';
import { useAuthDispatch, useAuthState, useGoogleAuth } from '../context/auth/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsSpin } from '@fortawesome/free-solid-svg-icons'
import { Button, Divider, Typography } from '@mui/material';
import { KivaSuiteLogo } from './Logo';
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import GlobalStyles from '@mui/material/GlobalStyles';
import { desktop_labels_l1, desktop_p1, montserratSemiBoldFontFamily } from '../styles/textStyles';
import { goldBtnStyles, regularBtnStyles } from '../styles/buttons';
import { loginIcon } from '../utils/icons';
import { goldColors, primaryColors } from '../styles/colors';
import { Link } from 'react-router-dom';
import { AuthState } from '../context/auth/AuthReducer';
import CircularProgressLoading from './CircularProgressLoading';

export const LoginLoading = () => {
  return <FontAwesomeIcon icon={faArrowsSpin} />
}

const LoginPage = () => {
  const auth: AuthState = useAuthState();
  const dispatch = useAuthDispatch()
  const googleAuth = useGoogleAuth()

  const { isLoading, isAuthenticated, getIdTokenClaims, error, user, loginWithRedirect, logout } = useAuth0();

  const login = async () => {
    await loginUserAuth0(dispatch)
  }

  return (
    <Box sx={rootContainerStyles}>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: '#fff', borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ display: 'grid', alignContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Link to={'/login'} style={{ display: 'flex', alignItems: 'center'}}><KivaSuiteLogo width='124px' /></Link>
          </Box>
          <Button onClick={() => login()} 
          sx={{
            fontFamily: montserratSemiBoldFontFamily,
            fontSize: '18px',
            fontStyle: 'normal',
            lineHeight: '155.556%',
            color:'#000', textTransform: 'none',
            padding:'6px 12px',
            '&:hover': { backgroundColor: goldColors.primary, borderColor: goldColors.primary }
          }} 
          endIcon={loginIcon('#000')}>
            {auth.loading ? <CircularProgressLoading boxStyles={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, padding: 0, width: "50px" }} circularProgressStyles={{width: "18px", height: "18px", color: '#FFF' }}/> : "Login" }
           </Button>
        </Toolbar>
      </AppBar>

      <Box sx={cardStyles}>

          <Box sx={{ display: 'grid', margin: 'auto', alignContent: 'center', justifyContent: 'center', flexGrow: 1, mb: '52px' }}>
            <KivaSuiteLogo width='180px' />
          </Box>

          <Box sx={{display: 'block', width: '78%', padding: '0 45px', margin:'auto', mb: '46px'}}>
            <Typography align="left" component="p" sx={desktop_p1} style={{marginBottom:'24px'}}>
              Kiva Suite includes tools designed by Kiva to support marketing and sales activities. 
            </Typography>
            <Typography align="left" component="p" sx={desktop_p1}>
              The tools are designed in collaboration with digital advertising, analytics and technology professionals to reduce manual work and improve results.
            </Typography>
          </Box>

          <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center', mb:'40px' }}>
            <Button onClick={() => login()} sx={{...goldBtnStyles, padding: "12px 24px", fontWeight: '600' }} style={{width: '300px'}}>
              {auth.loading ? <CircularProgressLoading boxStyles={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 0, padding: 0 }} circularProgressStyles={{width: "18px", height: "18px", color: '#FFF' }}/> : "Login" }</Button>
          </Box>

        <Box
          sx={{
            maxWidth: '668px',
            width:'80%'
          }}
        >
          <Divider sx={{height:'1px', background:'#000', margin:'auto'}}/>
          <Typography component="p" sx={desktop_labels_l1} align="center" style={{ marginTop:'16px' }}>
            {'© '}
            Kiva Suite{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </Box>

    </Box>);
}
export default LoginPage


const rootContainerStyles = {
  width: '100vw',
  height: '100vh', 
  flexShrink: 0,
  background: 'url("./kiva-suite-taustakuva.jpg")',
  backgroundSize: 'cover', 
  backgroundPosition: 'center', 
  backgroundRepeat: 'no-repeat',
  alignItems: 'center',
  justifyContent: 'center',
  position:'relative'
}

const cardStyles = { 
  position:'absolute',
  top: "calc(50% + 32px)",
  left:'50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems:'center',
  justifyContent:'center',
  transform: 'translate(-50%, -50%)',
  borderRadius: '15px',
  opacity: 0.9,
  background: '#fff',
  paddingTop: '80px',
  paddingBottom: '98px',
  width: '50%',
  ['@media (max-width: 780px)']: {
    width: '80%'
  }
}