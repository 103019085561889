import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { primaryColors } from '../styles/colors';
import dayjs from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/internals/models/range';

export type DatePickerMonthYearProps = {
  label: string
  date: Date
  onChange: (value: dayjs.Dayjs) => void
  onClose: () => void
}

export const DatePickerMonthYear = ({ label, date, onChange, onClose }: DatePickerMonthYearProps) => {

  const [currentDate, setCurrentDate] = React.useState<dayjs.Dayjs>(dayjs(date));

  const handleOnChange = (value: dayjs.Dayjs | null) => {
    if(value){
      setCurrentDate(value)
      onChange(value);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']} sx={{ ...stackRootStyles }}>
        <DatePicker
          label={label} 
          views={['month', 'year']}
          sx={{ ...styles }}
          value={currentDate}
          onChange={handleOnChange}
          format='MM.YYYY'
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}


const styles = {
  '& .MuiInputBase-input': {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  '& .MuiSvgIcon-root': {
    fill: primaryColors[500]
  },
  "& .MuiPickersDay-root": {
    "&.Mui-selected": {
      backgroundColor: primaryColors[500],
    },
  },
  '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
    backgroundColor: primaryColors[500],
  }
}

const stackRootStyles = {
  '&.MuiStack-root': { // '&' OR '&.MuiStack-root' selects current element MuiStack-root.
    paddingTop: '6px !important'
  }
}

