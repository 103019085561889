import { Box, Divider, Grid, Typography } from "@mui/material"
import IconButton from '@mui/material/IconButton';
import { CompetitorCreative } from "../../context/competitors/CompetitorTypes";
import { planeIcon, searchIcon } from "../../utils/icons";
import { neutralColors } from "../../styles/colors";
import { desktop_p2, desktop_p3 } from "../../styles/textStyles";
import { beautifyString, convertToFinnishDate, trimText } from "../../utils/helpers";
import { useEffect, useState } from "react";

export const mapCategory = (category: string): string => {
  switch (category) {
    case 'meta':
      return 'Meta';
    case 'SEARCH':
      return 'Google Search'
    case 'YOUTUBE':
      return 'Youtube'
    case 'SHOPPING':
        return 'Google Shopping'
    case 'PLAY':
      return 'Google Play'
    case 'MAPS':
      return 'Google Maps'
  }
  return category
}

export type CompetitorCreativeProps = {
  competitorCreative: CompetitorCreative
}

export const CompetitorCreativePreview = ({ competitorCreative }: CompetitorCreativeProps) => {
  const { id, creationTime, startTime,
    endTime, competitorName, competitorId,
    category, status, url, descriptions, externalUrls, titles, landingPageUrls, platforms, channel, width, height, impressions } = competitorCreative

  const landingPageURL = landingPageUrls?.length > 0 && !landingPageUrls[0].startsWith('https://') ? `https://${landingPageUrls[0]}` :  ""

  const handleSearchIconClick = (url: string, width: string, height: string) => {
    const previewData = { url, width, height };
    localStorage.setItem('previewData', JSON.stringify(previewData))
    window.open('/preview', '_blank');
  }

  return (<Box sx={boxContainerStyles}>

    {/* Heading */}
    <Grid container direction="row" style={{ justifyContent: 'space-between' }}>
      <Grid item sx={{paddingRight: '36px'}}>
        <Typography>{mapCategory(category)}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Typography>{competitorName}</Typography>
        </Box>
      </Grid>
    </Grid>

    {/*planeIcon*/}
    <Box sx={{position: 'absolute', top: 24, right:16, display: 'flex'}}>
      {landingPageURL &&
      <IconButton sx={{padding:0}} onClick={() => window.open(landingPageURL)}>
        {planeIcon()}
      </IconButton>}
    </Box>

    {/* media */}
    <Grid container sx={{ marginTop: '24px', border: '1px transparent solid', position: 'relative', alignItems: 'center', background: neutralColors[100] }}>

      <Box sx={mediaWrapper}>
        <Box sx={mediaContainer}>
          {channel === "meta" && <img src={`data:image/png;base64,${url}`} alt="preview" style={mediaElement} />}
          {channel === "google" && <img src={url} alt="preview" style={mediaElement} />}
        </Box>
      </Box>

      <Box sx={mediaOverlayIcons}>
        {channel === "meta" && url?.length > 0 &&
          <Box sx={{}}>
            <IconButton onClick={() => handleSearchIconClick(url, '100%', '100%')} sx={{ background: 'rgba(255,255,255,1)', padding: '6px' }}>
              {searchIcon()}
            </IconButton>
          </Box>}

          {channel === "google" && landingPageUrls?.length > 0  &&
          <Box sx={{}}>
            <IconButton onClick={() => window.open(landingPageUrls[0])} sx={{ background: 'rgba(255,255,255,1)', padding: '6px' }}>
              {searchIcon()}
            </IconButton>
          </Box>}
      </Box>

    </Grid>

    <Grid container direction="column" style={{ justifyContent: 'center', gap: '5px', marginTop: '24px' }}>
      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item xs={6}>
          <Typography sx={desktop_p3}><b>Start time</b></Typography>
          <Typography sx={desktop_p3}>{convertToFinnishDate(startTime).split(',')[0]}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={desktop_p3}><b>End time</b></Typography>
          <Typography sx={desktop_p3}>{endTime ?convertToFinnishDate(endTime).split(',')[0] : "-"}</Typography>
        </Grid>
      </Grid>
      {impressions && <Box sx={{marginTop: '12px'}}>
        <Typography sx={desktop_p3}><b>Impressions</b></Typography>
        <Typography sx={desktop_p3}>{impressions}</Typography>
      </Box>}
      {platforms.length > 0 && <Box sx={{marginTop: '12px'}}>
        <Typography sx={desktop_p3}><b>Platforms</b></Typography>
        <Typography sx={desktop_p3}>{platforms.map(p=> beautifyString(p)).join(", ")}</Typography>
      </Box>}
      {titles?.length > 0 && <Box sx={{marginTop: '12px'}}>
        <Typography sx={desktop_p3}><b>Title</b></Typography>
        <Typography sx={desktop_p3}>{trimText(titles[0], 50)}</Typography>
      </Box>}
      {descriptions?.length > 0 && <Box sx={{marginTop: '12px'}}>
        <Typography sx={desktop_p3}><b>Description</b></Typography>
        <Typography sx={desktop_p3}>{trimText(descriptions[0],116)}</Typography>
      </Box>}
    </Grid>
  </Box>)
}

// Style objects

const boxContainerStyles = {
  border: `1px solid ${neutralColors[100]}`,
  padding: '24px 16px',
  fontSize: '14px',
  lineHeight: '100%',
  borderRadius: '5px',
  width: '100%',
  position: 'relative'
}

const mediaWrapper = {
  border: "1px transparent solid",
  position: "relative",
  width: "100%",
  overflow: "hidden",
  paddingTop: "80%" /* 5:4 Aspect Ratio (divide 4 by 5 = 0.80) */

}
const mediaContainer = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
}
const textContainer = {
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  overflow: "scroll",
  padding: "16px"
}
const mediaElement = {
  "width": "100%",
  "height": "100%",
  "object-fit": "scale-down",
}

const iframeStyles = {
  "width": "200%",
  "height": "200%",
  "border": "none",
  "transform-origin": "top left",
  "transform": "scale(0.5)"
}

const mediaOverlayIcons = {
  "z-index": 5,
  position: "absolute",
  bottom: 10,
  right: 10,
  display: 'flex',
  flexDirection: 'row',
  border: '1px transparent solid',
  gap: '8px'
}