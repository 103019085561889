

import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { IdNameObj, MetaSettings, ReadpeakAdsSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { apiSaveReadpeakCredentials } from '../../api/Auth';
import { apiGetMetaSettings, apiGetReadpeakSettings, apiSaveMetaCompetitorSettings } from '../../api/Customer';
import { useAlertStateValue } from '../../context/alert/AlertContext';
import { setAlert } from '../../context/alert/AlertAction';
import { AlertSeverity } from '../../context/alert/AlertTypes';
import * as uuid from 'uuid'
import { regularBtnStyles } from '../../styles/buttons';
import { desktop_h3, montserratBoldFontFamily } from '../../styles/textStyles';
import { useCompetitorStateValue } from '../../context/competitors/CompetitorContext';

const textFieldTextStyles = {
  mb: 0
}

const gridItemStyles = {
  mt: 0,
  mb: 0
}

export type MetaCompetitorSettingsProps = {
  customerId: number
}

export const MetaCompetitorSettings = ({customerId}: MetaCompetitorSettingsProps) => {
  const [saveBtnProgress, setSaveBtnProgress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [{ alert }, alertDispatch] = useAlertStateValue()
  const [{ competitorAdUnits, competitorSettings }, competitorDispatch] = useCompetitorStateValue()
  const [settings, setSettings] = useState<any>({ userInput: "", facebookPages: competitorSettings.meta.facebookPages })

  useEffect(() => {
    setSettings({userInput: competitorSettings.meta.facebookPages?.map(i => `${i.name} (id: ${i.id})`).join(",\n"), facebookPages: competitorSettings.meta.facebookPages})
  }, [competitorSettings.meta])

  const handlePagesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, userInput: event.target.value })
  }

  const handleSavePages = () => {
    // Regular expression to match the name and id
    const regex = /([a-zA-Z0-9\s]+)\s+\(id:\s*(\d+)\)/g;
    let match;
    const pages = [];
    while ((match = regex.exec(settings.userInput)) !== null) {
      pages.push({
        name: match[1].trim(),
        id: match[2]
      });
    }
    if(pages.length > 0){
      setSaveBtnProgress(true)
      customerId && apiSaveMetaCompetitorSettings(customerId, pages).then((res: {facebookPages: IdNameObj[]}) => {
        setSettings({...settings, facebookPages: res.facebookPages})
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Saved successfully!", duration: 7000 }))
        setSaveBtnProgress(false)
      }).catch(error => {
        console.log("Save competitor Facebook pages error", error)
        alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Error while saving competitor's Facebook pages. ${error.message}`, duration: null }))
        setSaveBtnProgress(false)
      })
    }else{
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: `Please input Facebook pages in the following format: page 1 (id: 123456), page 2 (id: 654321).`, duration: null }))
    }

  }

  return (
  <Grid container>
    {loading ? <LoadingModal /> : <Grid item xs={12} sx={gridItemStyles}>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Typography sx={{...desktop_h3, fontFamily: montserratBoldFontFamily}}>Facebook Pages</Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 4 }}>
        {!settings.facebookPages && <Typography>Competitor Facebook pages have not been configured yet.</Typography>}
        {settings.facebookPages.length > 0 && settings.facebookPages?.map((p: IdNameObj) => (<Typography>- {p.name} (id: {p.id})</Typography>))}
      </Grid>
      <Grid component={"form"} container xs={12} sx={{ maxWidth: 1000 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={gridItemStyles}>
          <Typography sx={textFieldTextStyles}>Facebook pages (separated by comma)</Typography>
          <Typography sx={{fontSize: '14px'}}><b>For example:</b> page 1 (id: 123456), page 2 (id: 654321)</Typography>
          <TextField fullWidth multiline maxRows={8} minRows={6} size='small' variant="outlined" value={settings.userInput} onChange={handlePagesChange} />
        </Grid>

        <Grid item xs={12} sx={{ pt: 0 }}>
          <Button sx={{ ...regularBtnStyles, mt: 1, mb: 1.5 }} onClick={handleSavePages}>
            {saveBtnProgress ?  <CircularProgress  style={{width: '20px', height: '20px', color: "#fff"}} /> : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </Grid>}
  </Grid>
  )
}
